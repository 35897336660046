import { useContext, useEffect } from 'react';
// import useSound from 'use-sound';
import Modal from 'react-modal';
import * as S from './styles';
import CamContainer from '../../molecules/CamContainer';
import { OutScreen } from '../../templates/OutScreen';
import { MachineContext } from '../../../context';
import { InteractScreen } from '../../templates/InteractScreen';
import { useSocket } from '../../../hooks/socket';
import { colors } from '../../../styles/colors';
import Header from '../../molecules/Header';
import Icon from '../../atoms/Icon';
import { AiOutlineClose } from 'react-icons/ai';

// import start from '../../../assets/start.mp3';
import PaymentSuccess from '../../templates/PaymentSuccess';
import RescueMoneySuccess from '../../templates/RescueMoneySuccess';
import FeedbackContainer from '../../molecules/FeedbackContainer';
import MenuDrawer from '../../molecules/MenuDrawer';
import Menu from '../Menu';
import ExchangeSuccess from '../../templates/ExchangeSuccess';
import RescuePlushSuccess from '../../templates/RescuePlushSuccess';

Modal.setAppElement('#root');

export function Home() {
  const {
    status,
    setStatus,
    setSocket,
    user,
    gameToken,
    modalScreen,
    setModalScreen,
    feedback,
    setFeedback,
    showDesktopMenu,
    updateUserInfo,
    videoIsLoaded,
    setPlays,
    startGame,
  } = useContext(MachineContext);

  const isPlaying = status === 'P';
  const isOut = status === 'O';
  const isWaiting = status === 'W';
  const isReady = status === 'R';

  // const [play] = useSound(start);

  const validToken = gameToken;
  const isReadyToPlay = user.isLogged && (isReady || isPlaying) && !!validToken;

  useSocket(
    setSocket,
    isReadyToPlay,
    validToken,
    setStatus,
    setFeedback,
    user.id,
    updateUserInfo,
    setPlays,
    startGame
  );

  useEffect(() => {
    if (isPlaying && videoIsLoaded) {
      // play();
      startGame(60);
    }
  }, [isPlaying, startGame, videoIsLoaded]);

  return (
    <>
      <Header
        backgroundColor={colors.blue}
        showMenu={!(isReady || isPlaying)}
      />
      <MenuDrawer>
        <div>
          <Menu />
        </div>
        <div>
          <S.Container>
            <S.Content showMenu={showDesktopMenu}>
              {feedback !== '' && <FeedbackContainer type={feedback} />}
              {feedback === '' && (
                <>
                  <CamContainer />
                  <S.InteractContainer>
                    {isOut && <OutScreen />}
                    {(isPlaying || isWaiting || isReady) && <InteractScreen />}
                    {!(isPlaying || isReady) && (
                      <S.RedirectContainer>
                        <a
                          href="https://www.alabuta.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon type="signature" />
                        </a>
                      </S.RedirectContainer>
                    )}
                  </S.InteractContainer>
                </>
              )}
            </S.Content>
          </S.Container>
        </div>
      </MenuDrawer>
      <Modal
        className={`react-modal-content`}
        isOpen={!!modalScreen}
        onRequestClose={() => setModalScreen('')}
        overlayClassName="react-modal-overlay"
      >
        <S.CloseButton onClick={() => setModalScreen('')}>
          <AiOutlineClose size="24px" color="#00000099" />
        </S.CloseButton>
        {modalScreen === 'payment' && <PaymentSuccess />}
        {modalScreen === 'rescue' && <RescueMoneySuccess />}
        {modalScreen === 'exchange' && <ExchangeSuccess />}
        {modalScreen === 'rescuePlush' && <RescuePlushSuccess />}
      </Modal>
    </>
  );
}

export default Home;
