import * as S from './styles';
import { PlayButtonProps } from './types';

export function PlayButton({ onClick, children }: PlayButtonProps) {
  return (
    <S.PlayButtonContainer>
      <S.PlayButton onClick={() => onClick()}>{children}</S.PlayButton>
      <S.ExpandEffect />
    </S.PlayButtonContainer>
  );
}

export default PlayButton;
