import styled from 'styled-components';

interface InfoContainerProps {
  show: boolean;
}
export const InfoContainer = styled.div<InfoContainerProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')}};
  justify-content: space-around;
  align-items: center;
  max-width: 320px;
  width: 100%;
  gap: 10px;
`;
