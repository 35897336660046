import { useContext, useState } from 'react';
import { MachineContext } from '../../../context';
import HelpButton from '../../atoms/HelpButton';
import Instructions from '../../atoms/Instructions';
import PlayButton from '../../atoms/PlayButton';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import WaitingDisplay from '../../molecules/WaitingDisplay';
import { Alert, Snackbar } from '@mui/material';

export const OutScreen = () => {
  const [open, setOpen] = useState(true);
  const { user, showInstructions, setShowInstructions, modalScreen } =
    useContext(MachineContext);
  const navigateTo = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  setTimeout(() => {
    setOpen(false);
  }, 10000);

  return (
    <>
      {!user.isLogged && <WaitingDisplay show={!isDesktop} />}
      {showInstructions && (
        <Instructions
          onClose={() => setShowInstructions(false)}
          showSecondScreen={false}
        />
      )}
      {!showInstructions && (
        <PlayButton
          onClick={() => navigateTo(user.isLogged ? '/join' : '/login')}
        >
          {user.isLogged ? 'Jogar' : 'Logar'}
        </PlayButton>
      )}
      {!showInstructions && user.isLogged && (
        <HelpButton onClick={() => setShowInstructions(true)} />
      )}
      <Snackbar
        open={open && user.isLogged && modalScreen === ''}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ top: '50%' }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Não saia ou atualize o navegador para não perder as suas jogadas.
        </Alert>
      </Snackbar>
    </>
  );
};
