import Button from '../../atoms/Button';
import Input from '../../atoms/NewInput';
import LoginForm from '../../organisms/LoginForm';
import { useState } from 'react';
import { EmailFormProps } from './types';
import { validateEmail } from '../../../utils';
import userService from '../../../services/userService';
import { errorHandler } from '../../../services/api';
import { TitleContainer } from '../../organisms/LoginForm/styles';

export function EmailForm({ nextStep, email, setEmail }: EmailFormProps) {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onHandleChange = (value: string) => {
    setEmail(value);
    setHasError(false);
  };

  const onHandleClick = async () => {
    const isValidEmail = validateEmail(email);
    setHasError(!isValidEmail);
    if (!isValidEmail) return;

    try {
      setIsLoading(true);
      const response = await userService.postCheckEmail(email);
      nextStep(response.already_has_account);
    } catch (error) {
      alert(errorHandler(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginForm>
      <TitleContainer>
        <h3>Entrar ou Cadastrar</h3>
        <span>Informe seu e-mail para começar</span>
      </TitleContainer>
      <Input
        type="email"
        placeholder="E-mail"
        name="email"
        value={email}
        onChange={onHandleChange}
        hasError={hasError}
        errorMessage="E-mail inválido"
      />
      <Button
        onClick={() => {
          onHandleClick();
        }}
        color="#56A8E7"
        border={false}
        disabled={hasError || !email || isLoading}
      >
        Continuar
      </Button>
    </LoginForm>
  );
}

export default EmailForm;
