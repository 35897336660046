import styled from 'styled-components';
import { Device } from '../../../styles/device';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: auto;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;

  @media ${Device.Tablet} {
    margin-top: unset;
    max-width: 420px;
  }
`;

export const DirecionalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  width: max-content;
  transform: rotate(-45deg);
  margin: 30px;

  @media (max-width: 326px) {
    gap: 6px;
  }
`;
