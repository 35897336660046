import styled from 'styled-components';
import BackButton from '../../atoms/BackButton';
import { Device } from '../../../styles/device';
import InputWithWrapper from '../../atoms/InputWithWrapper';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100dvh - 69.25px);

  @media ${Device.Tablet} {
    max-width: 728px;
    margin: 20px auto;
    justify-content: flex-start;
    height: calc(100dvh - 140px);
  }
`;

export const Anchor = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #4978f0;
  font-family: IBM Plex Sans;
  font-size: 10px;
  font-weight: 300;
`;

export const AcceptTerms = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-family: IBM Plex Sans;
    font-size: 10px;
    font-weight: 400;
  }
`;

export const StyledBackButton = styled(BackButton)`
  margin: 14px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 20px;
  justify-content: center;

  h2 {
    color: rgba(0, 0, 0, 0.7);
    font-family: Krub;
    font-size: 24px;
    font-weight: 600;
  }
`;

export const ImageContainer = styled.div`
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(0.6933 * 100vw);
  margin-top: -1px;

  @media ${Device.Tablet} {
    min-height: auto;
    background: none;
    height: unset;
  }
`;

export const StyledInputWithWrapper = styled(InputWithWrapper)`
  width: calc(100% - 30px);
  margin: 15px;
`;

export const StyledButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: #488ee3;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.21px;
  display: flex;
`;
