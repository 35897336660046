export const getAuthCookie = () => {
  const cookies = document.cookie.split(';');
  const cookieByName = cookies.find((item) =>
    item.trim().startsWith('authorization')
  );

  if (!cookieByName) return null;

  const cookieByNameValue = cookieByName.trim().split('=')[1];
  return cookieByNameValue;
};

export const setAuthCookie = (token: string) => {
  if (!token) return;

  const expires = new Date();
  expires.setDate(expires.getDate() + 7);
  document.cookie = `authorization=${token};expires=${expires.toUTCString()};path=/`;
};

export const removeAuthCookie = () => {
  const expires = new Date();
  expires.setDate(expires.getDate() - 1);
  document.cookie = `authorization=;expires=${expires.toUTCString()};path=/`;
};

export const setOlmsCookie = (token: string) => {
  if (!token) return;
  document.cookie = token;
};

export const getOlmsCookie = () => {
  const cookies = document.cookie.split(';');
  const cookieByName = cookies.find((item) => item.trim().startsWith('olms'));

  if (!cookieByName) return null;

  const equalIndex = cookieByName.indexOf('=');
  if (equalIndex === -1) return null;

  const cookieByNameValue = cookieByName.substring(equalIndex + 1).trim();
  return cookieByNameValue;
};

export const removeOlmsCookie = () => {
  const expires = new Date();
  expires.setDate(expires.getDate() - 1);
  document.cookie = `olms=;expires=${expires.toUTCString()};path=/`;
};
