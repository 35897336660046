import styled from 'styled-components';
import { Device } from '../../../styles/device';

interface ContainerProps {
  showMenu: boolean;
}
export const Container = styled.div`
  background: linear-gradient(
    180deg,
    #f0f9ff 68.11%,
    #cee6f8 75.3%,
    #a6cfef 86.98%,
    #56a8e7 96.35%,
    #2185d1 100%
  );
`;

export const Content = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  height: calc(100dvh - 69.25px);

  @media ${Device.Tablet} {
    flex-direction: row;
    width: ${({ showMenu }: ContainerProps) => (showMenu ? 'unset' : '100vw')};
    height: calc(100dvh - 120px);
  }
`;

export const InteractContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  flex: 1;
  gap: 16px;
  justify-content: space-between;
  position: relative;

  @media ${Device.Tablet} {
    height: 100%;
    justify-content: center;
  }
`;

export const RedirectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 16px;

  @media ${Device.Tablet} {
    display: none;
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 20px;

  @media ${Device.Tablet} {
    display: flex;
  }
`;
