import { useContext } from 'react';
import { MachineContext } from '../../../context';
import InformationSpan from '../../atoms/InformationSpan';
import * as S from './styles';
import { InformationDisplayProps } from './types';
import Icon from '../../atoms/Icon';

export function InformationDisplay({
  playsQuantity,
  position,
  className,
}: InformationDisplayProps) {
  const { seconds, status, isActive } = useContext(MachineContext);

  const isPlaying = status === 'P';
  return (
    <S.InfoContainer className={className}>
      <InformationSpan color="#b18282" padding="6px 10px" size="18px">
        {isPlaying ? (
          <>
            <Icon type="clock" /> {seconds}
          </>
        ) : (
          `Posição ${position}`
        )}
      </InformationSpan>
      <InformationSpan
        color="#b18282"
        padding="6px 10px"
        size="18px"
        flex={isPlaying ? '1' : '0'}
      >
        <Icon type="garraMenu2" /> {playsQuantity}
      </InformationSpan>
      <InformationSpan
        color={isPlaying ? (isActive ? '#6D8D6F' : '#fae012') : '#fae012'}
        padding="6px 10px"
        size="18px"
      >
        {isPlaying ? (isActive ? 'Jogando' : 'Aguarde') : 'Carregando'}
      </InformationSpan>
    </S.InfoContainer>
  );
}

export default InformationDisplay;
