import * as S from './styles';
import { ButtonProps } from './types';

export function Button({
  children,
  onClick,
  disabled,
  color,
  className,
  border = true,
}: ButtonProps) {
  return (
    <S.SubmitButton
      disabled={disabled}
      onClick={onClick}
      color={color}
      className={className}
      border={border}
    >
      {children}
    </S.SubmitButton>
  );
}

export default Button;
