import { VscDebugBreakpointUnsupported } from 'react-icons/vsc';
import * as S from './styles';
import { AlertProps } from './types';

export function Alert({ children, className }: AlertProps) {
  return (
    <S.Container className={className}>
      <VscDebugBreakpointUnsupported size={24} />
      <span>{children}</span>
    </S.Container>
  );
}

export default Alert;
