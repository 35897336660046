import styled from 'styled-components';
import InputWithWrapper from '../../atoms/InputWithWrapper';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

export const Anchor = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #4978f0;
  font-family: IBM Plex Sans;
  font-size: 10px;
  font-weight: 300;
`;

export const AcceptTerms = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: pointer;

  span {
    font-family: IBM Plex Sans;
    font-size: 10px;
    font-weight: 400;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
    color: #4978f0;
    font-family: IBM Plex Sans;
    font-size: 10px;
    font-weight: 400;
  }

  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #bfe0f9;
    cursor: pointer;
  }

  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #bfe0f9;
    content: '';
    display: inline-block;
    visibility: visible;
    cursor: pointer;
    border: 2px solid #bfe0f9;
  }
`;

export const StyledButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: #488ee3;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.21px;
  display: flex;
`;

export const StyledInputWithWrapper = styled(InputWithWrapper)`
  width: calc(100% - 30px);
  margin: 15px;
`;
