import styled from 'styled-components';
import { Device } from '../../../styles/device';

export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  background: #f0f8ff;
  width: calc(100% - 30px);
  padding: 20px;
  font-family: Krub;
  gap: 24px;
  margin: 20px auto 20px;

  h3 {
    color: rgba(0, 0, 0, 0.7);
    font-family: Krub;
    font-size: 24px;
    font-weight: 700;
  }

  h4 {
    color: #02031a;
    font-size: 16px;
    font-weight: 700;
  }

  span {
    color: #000;
    font-size: 16px;
    font-weight: 300;
  }

  input {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 23.4px */
    letter-spacing: 0.18px;
  }

  @media ${Device.Tablet} {
    flex: unset;
  }
`;

export const RedirectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 16px;
  padding: 20px;
  flex: 1;

  @media ${Device.Tablet} {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;
