import styled from 'styled-components';
import { Device } from '../../../styles/device';
import WaitingDisplay from '../../molecules/WaitingDisplay';
import InformationDisplay from '../InformationDisplay';

interface CamContainerProps {
  showMenu: boolean;
}
export const CamContainer = styled.div<CamContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  flex: 2;
  position: relative;

  iframe {
    width: 100%;
    height: 75%;
  }

  h2 {
    text-align: center;
    font-family: Poppins;
  }

  @media ${Device.Tablet} {
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: ${({ showMenu }) => (showMenu ? 'none' : '3px solid #f0f9ff')};
    box-shadow: ${({ showMenu }) =>
      showMenu ? 'none' : '0px 5px 8px 0px rgba(0, 0, 0, 0.25)'};
    margin: 32px;
    height: 75%;
  }
`;

export const SecondCamContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 40%;
  background: white;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const CustomWaitingDisplay = styled(WaitingDisplay)`
  margin-top: 20px;
`;

export const CustomInformationDisplay = styled(InformationDisplay)`
  margin-top: 20px;
`;
