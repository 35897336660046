import { ChangeEvent, useState } from 'react';
import * as S from './styles';
import { NewInputProps } from './types';

import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

export function NewInput({
  label,
  name,
  placeholder,
  type,
  hasError,
  errorMessage,
  value,
  onChange,
}: NewInputProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <S.InputWrapper error={hasError}>
      {label && <S.InputLabel htmlFor={name}>{label}</S.InputLabel>}
      <S.Input
        id={name}
        placeholder={placeholder}
        error={hasError}
        paddingRight={type === 'password'}
        type={
          type === 'password' ? (!showPassword ? 'password' : 'text') : type
        }
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange(event.currentTarget.value)
        }
      />
      {hasError && <span className="error">{errorMessage}</span>}
      {type === 'password' &&
        (showPassword ? (
          <AiFillEye
            size="22px"
            color="#B8BCCA"
            onClick={() => setShowPassword(false)}
            data-testid="hide-password-button"
          />
        ) : (
          <AiFillEyeInvisible
            size="22px"
            color="#B8BCCA"
            onClick={() => setShowPassword(true)}
            data-testid="show-password-button"
          />
        ))}
    </S.InputWrapper>
  );
}

export default NewInput;
