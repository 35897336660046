import * as S from './styles';
import { colors } from '../../../styles/colors';

import { useState } from 'react';
import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import { AddressFormProps } from './types';
import { Alert } from '@mui/material';

export function AddressForm({ onSubmit, setResumeWordings }: AddressFormProps) {
  const [fullName, setFullName] = useState('');
  const [CEP, setCEP] = useState('');
  const [state, setState] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [city, setCity] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [complement, setComplement] = useState('');
  const [open, setOpen] = useState(true);

  return (
    <>
      <S.Form>
        <Input
          type="text"
          placeholder="Digite seu nome completo"
          label="Nome completo"
          name="fullName"
          value={fullName}
          onChange={setFullName}
        />
        <S.DoubleColumn limitSizeOn={1} maxWidth={100}>
          <Input
            type="number"
            placeholder="00000-000"
            label="CEP"
            name="CEP"
            value={CEP}
            onChange={setCEP}
          />
          <Input
            type="text"
            placeholder="Estado"
            label="Estado"
            name="state"
            value={state}
            onChange={setState}
          />
        </S.DoubleColumn>
        <S.DoubleColumn limitSizeOn={2} maxWidth={60}>
          <Input
            type="text"
            placeholder="Logradouro"
            label="Rua"
            name="street"
            value={street}
            onChange={setStreet}
          />
          <Input
            type="text"
            placeholder="0"
            label="Número"
            name="number"
            value={number}
            onChange={setNumber}
          />
        </S.DoubleColumn>
        <S.DoubleColumn limitSizeOn={1} maxWidth={100}>
          <Input
            type="text"
            placeholder="Bairro"
            label="Bairro"
            name="neighborhood"
            value={neighborhood}
            onChange={setNeighborhood}
          />
          <Input
            type="text"
            placeholder="Casa / Apt / Bloco"
            label="Complemento"
            name="complement"
            value={complement}
            onChange={setComplement}
          />
        </S.DoubleColumn>
        <Input
          type="text"
          placeholder="Cidade"
          label="Cidade"
          name="city"
          value={city}
          onChange={setCity}
        />
      </S.Form>
      {open && (
        <Alert onClose={() => setOpen(false)} severity="info" variant="filled">
          Durante a fase Beta, as pelúcias capturadas não serão necessariamente
          as mesmas a serem resgatadas. As pelúcias para resgate são as
          disponíveis em estoque e enviadas aleatóriamente.
        </Alert>
      )}
      <Button
        onClick={() => {
          setResumeWordings({
            fullName,
            CEP,
            state,
            street,
            number,
            city,
            neighborhood,
            complement,
          });
          onSubmit();
        }}
        disabled={
          !fullName || !CEP || !state || !street || !city || !neighborhood
        }
        color={colors.blue}
      >
        Continuar
      </Button>
    </>
  );
}

export default AddressForm;
