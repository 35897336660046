import styled from 'styled-components';
import Button from '../../atoms/Button';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  font-family: Montserrat;

  span {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.4%; /* 24.012px */
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

export const StyledButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
  border: 1px solid #78cc1f;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
`;
