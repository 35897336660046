import * as S from './styles';

export function PrivacyPolicy() {
  return (
    <S.Content>
      <h2>
        Políticas de uso e privacidade para a aplicação de jogo
        OnlineMachine.com.br
      </h2>
      <h3>Política de uso</h3>
      <h4>1. Aceitação dos Termos</h4>
      <span>
        Bem-vindo (a) a onlineMachine.com.br! Ao acessar ou utilizar nosso
        Website e/ou aplicativo, você concorda em cumprir e estar sujeito a
        estes Termos de Uso. Este documento estabelece as regras e condições
        para o uso do nosso website e aplicativo de jogo. Ao acessar e utilizar
        nossa plataforma, você concorda em seguir nossas diretrizes. É proibida
        a violação dos direitos autorais ou qualquer uso impróprio da
        OnlineMachine.com.br.
      </span>
      <h4>2. Alteração nos termos</h4>
      <span>
        Reservamo-nos o direito de modificar estes Termos de Uso a qualquer
        momento. Quaisquer alterações serão publicadas nesta página e a
        continuidade do uso do Website ou aplicativo após a publicação das
        mudanças implica e compreende-se a aceitação dos novos termos.
      </span>
      <h4>3. Elegibilidade</h4>
      <span>
        Para utilizar este website e/ou aplicativo você deve ter pelo menos 13
        anos de idade. Usuários menores de 18 anos devem obter permissão dos
        pais ou responsáveis para usar o website ou aplicativo.
      </span>
      <h4>4. Registro de segurança da conta</h4>
      <span>
        Para utilizar os recursos da aplicação, você precisará criar uma conta.
        Você é responsável por manter a confidencialidade de suas informações de
        login e por todas as atividades que ocorram na sua conta. Notifique-nos
        imediatamente em caso de uso não autorizado de sua conta.
      </span>
      <h4>5. Uso aceitável</h4>
      <span>
        Você concorda em utilizar o website e/ou aplicativo para o fim que ele
        se destina, não sendo aceitável qualquer outra utilização. Você tem
        ciência de que não serão tolerados qualquer finalidade ilegal ou não
        autorizada, como também interferir ou interromper o funcionamento da
        aplicação, assediar, ameaçar ou prejudicar outros usuários.
      </span>
      <h4>6. Propriedade Intelectual</h4>
      <span>
        Todos os direitos, títulos e interesses relativos à aplicação,
        incluindo, mas não se limitando a, conteúdo, gráficos, interface e
        código, são propriedade exclusiva da onlineMachine.com.br e Alabuta
        ltda. Não é permitido copiar ou reproduzir, modificar, vender ou alugar
        qualquer parte da aplicação sem a nossa autorização.
      </span>
      <h4>7. Compras de jogadas</h4>
      <span>
        A OnlineMachine oferece compras para que se possa jogar. Todas as
        compras são finais e não reembolsáveis, exceto conforme exigido pela lei
        aplicável.
      </span>
      <h4>8. Prêmio</h4>
      <span>
        As pelúcias capturadas não serão necessariamente as mesmas a serem
        resgatadas. As pelúcias para resgate são as disponíveis em estoque no
        momento da solicitação. Também é possível trocar as pelúcias por
        jogadas, após escolher uma das duas formas para resgate de pelúcias, a
        ação não poderá ser desfeita.
      </span>
      <h4>9. Prazo</h4>
      <span>
        O prazo para resgate de pelúcias é informado após o cálculo do frete. No
        entanto, a confirmação de pagamento pode influenciar no prazo final que
        será informado por e-mail após a instituição financeira confirmar o
        pagamento.
      </span>
      <h4>10. Reembolso de jogadas</h4>
      <span>
        As jogadas selecionadas e não finalizadas são reembolsadas
        automaticamente alguns instantes após você ser desconectado por motivo
        da máquina entrar em abastecimento ou manutenção durante o seu tempo de
        jogo. Se o reembolso for necessário por qualquer outro motivo, você deve
        contatar nosso suporte que irá analisar individualmente a sua
        solicitação e o prazo para resposta é de 5 dias úteis. Jogadas perdidas
        por desconexão em consequência de baixa qualidade no serviço da rede de
        Internet do jogador não serão reembolsadas. Antes de entrar na fila,
        certifique-se da estabilidade na sua rede de Internet.
      </span>
      <h4>11. Cancelamento e Encerramento</h4>
      <span>
        Reservamo-nos o direito de suspender ou encerrar sua conta e acesso ao
        website e aplicativo a qualquer momento, sem aviso prévio, se
        acreditarmos que você violou esta Política de Uso ou por qualquer outro
        motivo. Você também possui a liberdade, se assim preferir, de excluir a
        conta a qualquer momento.
      </span>
      <h4>12. Limitação de Responsabilidade</h4>
      <span>
        Na extensão máxima permitida pela lei aplicável, onlineMachine.com.br
        não será responsável por quaisquer danos indiretos, acidentais,
        consequenciais ou punitivos decorrentes do mau uso ou incapacidade de
        usar o website ou aplicativo.
      </span>
      <h4>13. Indenização</h4>
      <span>
        Você concorda em indenizar, defender e isentar a onlineMachine.com.br de
        qualquer reclamação, responsabilidade, dano ou despesa decorrente de sua
        violação desta Política de Uso ou do uso inadequado do Website ou
        aplicativo.
      </span>
      <h4>14. Lei Aplicável</h4>
      <span>
        Estes Termos de Uso serão regidos e interpretados de acordo com as leis
        de São Paulo/Brasil e aLGPD, sem considerar os conflitos de disposições
        legais.
      </span>
      <h4>15. Contato</h4>
      <span>
        Em caso de dúvidas sobre estes Termos de Uso, entre em contato conosco
        através do endereço de e-mail:{' '}
        <a href="mailto:sac@onlinemachine.com.br">sac@onlinemachine.com.br</a>.
      </span>
      <h4>16. Disposições Gerais</h4>
      <span>
        Se qualquer parte destes Termos de Uso for considerada inválida ou
        inexequível, essa parte será interpretada de acordo com a lei aplicável,
        e as partes restantes permanecerão em pleno vigor de efeito.
      </span>

      <h3>Política de privacidade</h3>
      <h4>1. Introdução</h4>
      <span>
        Esta política de privacidade descreve como a onlineMachine.com.br
        coleta, usa, armazena e protege as informações pessoais dos usuários do
        nosso website e aplicativo de jogo. Ao utilizar o nosso Website e/ou
        aplicativo, você concorda com a coleta e o uso das informações de acordo
        com esta política.
      </span>
      <h4>2. Informações coletadas</h4>
      <span>
        Para proporcionar a melhor experiência de jogo, coletamos informações
        pessoais, como apelido, CPF e endereço de e-mail. Esses dados são
        necessários para criar e gerenciar sua conta no site seguindo as
        diretrizes da LGPD. Coletamos também informações do dispositivo, como
        tipo de dispositivo e endereço IP.
      </span>
      <h4>3. Uso das Informações</h4>
      <span>
        As informações pessoais coletadas são utilizadas para personalizar sua
        experiência de jogo e fornecer suporte ao cliente. Como também gerenciar
        sua conta, personalizar a experiência do usuário, melhorar as
        funcionalidades da aplicação, monitorar e prevenir fraudes e outras
        atividades ilegais e proteger a segurança de nossos usuários.
      </span>
      <h4>4. Compartilhamento das Informações</h4>
      <span>
        Nós compartilhamos suas informações nas seguintes circunstâncias: com
        provedores de serviços que realizam funções em nosso nome, como
        hospedagem de servidores, análise de dados e suporte ao cliente. Em
        conformidade legal, quando exigido por lei para proteger nossos
        direitos, segurança e propriedade, ou o de nossos usuários e público. Em
        caso de fusão, aquisição ou venda de ativos, suas informações podem ser
        transferidas como parte da transação. Nós não compartilhamos suas
        informações com terceiros além das circunstâncias apresentadas, exceto
        quando exigido por lei ou com o seu consentimento.
      </span>
      <h4>5. Armazenamento e Segurança das Informações</h4>
      <span>
        Implementamos medidas de segurança avançadas para proteger suas
        informações pessoais contra acesso não autorizado e uso indevido. No
        entanto, nenhum método de transmissão pela internet ou armazenamento
        eletrônico é 100% seguro, e não podemos garantir a segurança absoluta.
      </span>
      <h4>6. Confidencialidade</h4>
      <span>
        Suas informações pessoais são tratadas de forma confidencial e são
        armazenadas em servidores seguros.
      </span>
      <h4>7. Seus Direitos</h4>
      <span>
        Você tem o direito de solicitar acesso às suas informações pessoais e
        corrigir quaisquer dados imprecisos. Pedir a exclusão da sua conta e
        informações pessoais, sujeito a certas exceções conforme a lei
        aplicável. Optar por não receber comunicações promocionais seguindo as
        instruções de cancelamento de inscrição em nossos e-mails.
      </span>
      <h4>8. Retenção dos Dados</h4>
      <span>
        As informações pessoais serão retidas apenas pelo tempo necessário para
        cumprir os propósitos mencionados nesta política, a menos que exigido
        por lei.
      </span>
      <h4>9. Cookies de Rastreamento</h4>
      <span>
        Utilizamos cookies e tecnologias de rastreamento para melhorar sua
        experiência de jogo. Os cookies são pequenos arquivos que são
        armazenados no seu dispositivo e ajudam a lembrar suas preferências e
        atividades anteriores, ao fazer login você concorda com o uso dos
        cookies.
      </span>
      <h4>10 .Privacidade Infantil</h4>
      <span>
        Nosso website e aplicativo não é destinado a crianças menores de 13
        anos. Não coletamos intencionalmente informações pessoais de crianças
        menores de 13 anos. Se soubermos que coletamos informações pessoais de
        criança menor de 13 anos sem verificação do consentimento dos pais,
        tomaremos medidas para remover essas informações.
      </span>
      <h4>11. Responsabilidade Legal</h4>
      <span>
        Embora tomemos todas as precauções necessárias para proteger suas
        informações pessoais, não podemos garantir a segurança absoluta da
        transmissão de dados pela Internet. Ao concordar com estes termos, você
        reconhece a necessidade de utilizar uma senha segura e não
        compartilhá-la com terceiros. A senha é pessoal e não nos
        responsabilizamos pelas consequências decorrentes do compartilhamento.
      </span>
      <h4>12. Alterações a esta Política de Privacidade</h4>
      <span>
        Podemos atualizar esta Política de Privacidade periodicamente.
        Notificamos sobre quaisquer alterações publicando a nova política nesta
        página. Recomendamos que você revise esta política regularmente para se
        manter informado sobre nossasnpráticas de privacidade.
      </span>
      <h4>13. Contato</h4>
      <span>
        Se você tiver dúvidas ou preocupações sobre esta Política de
        Privacidade, entre em contato conosco através do endereço de e-mail:{' '}
        <a href="mailto:sac@onlinemachine.com.br">sac@onlinemachine.com.br</a>.
      </span>
      <span>
        Obrigado por escolher a onlineMachine.com.br! Aproveite sua experiência
        de jogo.
      </span>
    </S.Content>
  );
}

export default PrivacyPolicy;
