import * as S from './styles';
import Header from '../../molecules/Header';
import { colors } from '../../../styles/colors';
import { MdOutlineAutoAwesomeMotion } from 'react-icons/md';
import { useContext, useEffect, useState } from 'react';
import Button from '../../atoms/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { MachineContext } from '../../../context';
import MenuDrawer from '../../molecules/MenuDrawer';
import Menu from '../Menu';
import { useMediaQuery } from 'react-responsive';
import safePurchase from '../../../assets/safe_purchase.svg';
import ssl from '../../../assets/ssl.svg';
import Icon from '../../atoms/Icon';

export function Checkout() {
  const [alreadyCopied, setAlreadyCopied] = useState(false);
  const { copyAndPastePix, pixQrCode, modalScreen } =
    useContext(MachineContext);

  const { amount } = useParams();
  const navigate = useNavigate();

  const isDesktop = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    if (modalScreen === 'payment') navigate('/');
  }, [modalScreen, navigate]);

  return (
    <>
      <Header backgroundColor={colors.blue} />
      <MenuDrawer>
        <div>
          <Menu />
        </div>
        <div>
          <S.Container>
            <S.ImageContainer>
              <S.StyledBackButton showInitialButton />
              <S.TitleContainer>
                <h2>Solicitação recebida com sucesso!</h2>
                <div>
                  <span>Pagamento via Pix</span>
                  <span>
                    Total{' '}
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(parseInt(amount || '0'))}
                  </span>
                </div>
              </S.TitleContainer>
            </S.ImageContainer>
            <S.Content>
              <S.StepsContainer>
                <div>1</div>
                <span>
                  Copie o código {isDesktop && 'ou escaneie o QR Code abaixo'}
                </span>
              </S.StepsContainer>
              <S.PaymentInfoWrapper>
                <div>
                  <S.PixInput type="text" value={copyAndPastePix} />
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(copyAndPastePix);
                      setAlreadyCopied(true);
                      setTimeout(() => {
                        setAlreadyCopied(false);
                      }, 4000);
                    }}
                    {...(alreadyCopied && { color: '#d2f8d2' })}
                  >
                    <MdOutlineAutoAwesomeMotion size={24} />
                    {alreadyCopied ? 'Copiado' : 'Copiar código'}
                  </Button>
                </div>

                {isDesktop && (
                  <img height={300} width={300} src={pixQrCode} alt="QR Code" />
                )}
              </S.PaymentInfoWrapper>
              <S.StepsContainer>
                <div>2</div>
                <span>
                  Vá até a área Pix do app do seu banco e escolha "Pagar com Pix
                  copia e cola”
                </span>
              </S.StepsContainer>
              <S.StepsContainer>
                <div>3</div>
                <span>
                  Cole o código, confira as informações e finalize o pagamento
                  em 5 minutos
                </span>
              </S.StepsContainer>
            </S.Content>
            <S.InfoWrapper>
              <span>
                Após realizar o pagamento é só aguardar alguns instantes, será
                exibida uma mensagem aqui na plataforma com a confirmação.
              </span>
              <div>
                <img height={50} src={safePurchase} alt="Compra segura" />
                <img height={50} src={ssl} alt="Site com certificado" />
              </div>
            </S.InfoWrapper>
          </S.Container>
          <S.RedirectContainer>
            <a href="https://www.alabuta.com/" target="_blank" rel="noreferrer">
              <Icon type="signature" />
            </a>
          </S.RedirectContainer>
        </div>
      </MenuDrawer>
    </>
  );
}

export default Checkout;
