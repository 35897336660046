import styled, { keyframes } from 'styled-components';
import { Device } from '../../../styles/device';

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    visibility: hidden;
  }
  to {
    transform: translateX(0);
    visibility: visible;
  }
`;

interface WrapperProps {
  showMenu: boolean;
}
export const Wrapper = styled.div<WrapperProps>`
  display: flex;

  & > div {
    &:first-child {
      ${({ showMenu }) => !showMenu && `display: none;`}
      flex: 1;
      transition: transform 0.5s;
      animation: ${slideIn} 0.5s;
      transform-origin: left;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 8px 0px;
    }

    &: last-child {
      flex: 2.5;
    }
  }

  @media ${Device.Tablet} {
    height: calc(100dvh - 120px);
    justify-content: center;
  }
`;
