import React, { memo, Suspense } from 'react';
import { IconProps } from './types';

const Icons = {
  garra: React.lazy(() => import('./assets/garra')),
  clock: React.lazy(() => import('./assets/clock')),
  help: React.lazy(() => import('./assets/help')),
  up: React.lazy(() => import('./assets/up')),
  down: React.lazy(() => import('./assets/down')),
  left: React.lazy(() => import('./assets/left')),
  right: React.lazy(() => import('./assets/right')),
  back: React.lazy(() => import('./assets/back')),
  balloon: React.lazy(() => import('./assets/balloon')),
  security: React.lazy(() => import('./assets/security')),
  garraMenu: React.lazy(() => import('./assets/garraMenu')),
  garraMenu2: React.lazy(() => import('./assets/garraMenu2')),
  signature: React.lazy(() => import('./assets/signature')),
  wpp: React.lazy(() => import('./assets/wpp')),
  logout: React.lazy(() => import('./assets/logout')),
};

const Icon = ({ type, ...props }: IconProps) => {
  const IconComponent = Icons[type];
  return (
    <Suspense>
      <IconComponent {...props} data-testid="icon-component" />
    </Suspense>
  );
};

export default memo(Icon);
