import styled, { keyframes } from 'styled-components';

const waveAnimation = keyframes`
  0% {
    width: 100px;
    height: 100px;
    opacity: 1;
  }
  80% {
    width: 180px;
    height: 180px;
    opacity: 0.2;
  }
  100% {
    width: 180px;
    height: 180px;
    opacity: 0;
  }
`;

export const PlayButtonContainer = styled.div`
  position: relative;
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  border-radius: 50%;
  background-color: #fae012;
  z-index: 1;
`;

export const PlayButton = styled.button`
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background-color: #fae012;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: none;
  color: #b18282;
  font-family: Montserrat Alternates;
  font-size: 17.5px;
  font-weight: 600;
  line-height: 133.4%; /* 23.345px */
`;

export const ExpandEffect = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate3d(0, 0, 0);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(250, 224, 18, 0.3);
  animation: ${waveAnimation} 2.5s ease-out infinite;
  z-index: -1;
`;
