import Button from '../../atoms/Button';
import Input from '../../atoms/NewInput';
import LoginForm from '../../organisms/LoginForm';
import { useContext, useEffect, useState } from 'react';

import * as S from './styles';
import Header from '../../molecules/Header';
import { colors } from '../../../styles/colors';
import { useNavigate, useSearchParams } from 'react-router-dom';
import userService from '../../../services/userService';
import { MachineContext } from '../../../context';
import { validateStrongPassword } from '../../../utils';
import { errorHandler } from '../../../services/api';
import MenuDrawer from '../../molecules/MenuDrawer';
import Menu from '../Menu';

export function ForgotPass() {
  const { user } = useContext(MachineContext);
  const [password, setPassword] = useState('');
  const [mailCode, setMailCode] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passHasError, setPassHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email') || '';

  const navigate = useNavigate();

  useEffect(() => {
    setPassHasError(false);
  }, [password, repeatPassword]);

  const onHandleClick = async () => {
    const invalidPasswordReason = validateStrongPassword(password);
    if (invalidPasswordReason) {
      setPasswordError(invalidPasswordReason);
      return;
    }

    if (password !== repeatPassword) {
      setPassHasError(true);
      return;
    }

    try {
      setIsLoading(true);
      await userService.putResetPassword(user.email, mailCode, password);
      navigate('/login');
    } catch (error) {
      alert(errorHandler(error));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (seconds) {
      const interval = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds]);

  const onHandleResendCode = async () => {
    if (seconds) return;
    try {
      await userService.postResendCode(email, 'validate_account');
      setSeconds(30);
    } catch (error) {
      alert(errorHandler(error));
    }
  };

  return (
    <>
      <Header backgroundColor={colors.blue} />
      <MenuDrawer>
        <div>
          <Menu />
        </div>
        <div>
          <S.Container>
            <S.StyledBackButton />
            <S.TitleContainer>
              <h2>Cadastrar nova senha</h2>
            </S.TitleContainer>
            <h4 style={{ margin: '16px 15px 0' }}>{email}</h4>
            <S.StyledInputWithWrapper
              type="text"
              placeholder="** ** ** **"
              label="Insira o código que enviamos para o seu e-mail"
              name="mailCode"
              value={mailCode}
              onChange={setMailCode}
            >
              <S.StyledButton onClick={onHandleResendCode} disabled={!!seconds}>
                Reenviar Código{' '}
                {seconds
                  ? `00:${seconds.toLocaleString('pt-BR', {
                      minimumIntegerDigits: 2,
                    })}`
                  : ''}
              </S.StyledButton>
            </S.StyledInputWithWrapper>
            <LoginForm>
              <Input
                type="password"
                placeholder="Informe a nova senha"
                name="password"
                value={password}
                onChange={setPassword}
                errorMessage={passwordError}
                hasError={passwordError !== ''}
              />
              <Input
                type="password"
                placeholder="Repita sua senha"
                name="repeatPassword"
                value={repeatPassword}
                onChange={setRepeatPassword}
                errorMessage="As senhas não coincidem"
                hasError={passHasError}
              />
              <Button
                onClick={() => {
                  onHandleClick();
                }}
                color="#56A8E7"
                border={false}
                disabled={
                  passHasError ||
                  isLoading ||
                  !password ||
                  !repeatPassword ||
                  !mailCode
                }
              >
                Salvar
              </Button>
            </LoginForm>
          </S.Container>
        </div>
      </MenuDrawer>
    </>
  );
}

export default ForgotPass;
