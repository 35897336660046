export const isMobileDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const validateEmail = (mail: string) =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);

export const validateCpf = (cpf: string) => {
  const cpfRegex = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}$/;
  return cpfRegex.test(cpf);
};

export const validateNickname = (name: string) => {
  const isValid = /^[a-zA-Z0-9_]{3,20}$/.test(name);

  if (!isValid) {
    if (name.length < 3) {
      return 'O nome de usuário deve ter no mínimo 3 caracteres.';
    }

    if (name.length > 20) {
      return 'O nome de usuário deve ter no máximo 20 caracteres.';
    }

    return 'O nome de usuário deve conter apenas letras, números e underline.';
  }

  return '';
};

export const validateStrongPassword = (password: string) => {
  const isValid =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(
      password
    );

  if (!isValid) {
    if (password.length < 8) {
      return 'A senha deve ter no mínimo 8 caracteres.';
    }

    return 'A senha deve conter pelo menos uma letra maiúscula, uma minúscula, um número e um caractere especial.';
  }

  return '';
};
