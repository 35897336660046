import { ChangeEvent, useState } from 'react';
import * as S from './styles';
import { InputWithWrapperProps } from './types';

import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

export function InputWithWrapper({
  label,
  name,
  placeholder,
  type,
  hasError,
  errorMessage,
  value,
  className,
  onChange,
  children,
}: InputWithWrapperProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <S.InputWithWrapperContainer className={className}>
      {label && (
        <S.InputWithWrapperLabel htmlFor={name}>
          {label}
        </S.InputWithWrapperLabel>
      )}
      <S.InputWithWrapper
        id={name}
        placeholder={placeholder}
        error={hasError}
        type={type === 'password' && !showPassword ? 'password' : 'text'}
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange(event.currentTarget.value)
        }
      />
      {hasError && <span className="error">{errorMessage}</span>}
      {type === 'password' &&
        (showPassword ? (
          <AiFillEye
            size="22px"
            color="#B8BCCA"
            onClick={() => setShowPassword(false)}
            data-testid="hide-password-button"
          />
        ) : (
          <AiFillEyeInvisible
            size="22px"
            color="#B8BCCA"
            onClick={() => setShowPassword(true)}
            data-testid="show-password-button"
          />
        ))}
      {children}
    </S.InputWithWrapperContainer>
  );
}

export default InputWithWrapper;
