import styled from 'styled-components';
import BackButton from '../../atoms/BackButton';
import { Device } from '../../../styles/device';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100dvh - 80px);
  margin-top: 10px;

  @media ${Device.Tablet} {
    max-width: 728px;
    margin: 20px auto;
    justify-content: flex-start;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  gap: 20px;
  flex: 1;
  margin-top: 10px;

  @media ${Device.Tablet} {
    flex: unset;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  height: 80%;

  h2 {
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
  }

  span {
    color: rgba(0, 0, 0, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
  }

  p {
    color: #bfe0f9;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    -webkit-text-stroke: 0.3px black;
    font-size: 18px;
    font-weight: 700;
    margin: auto 0;
  }
`;

export const ImageContainer = styled.div`
  display: block;
  width: 100%;
  margin-top: -1px;
`;

export const StyledBackButton = styled(BackButton)`
  margin-left: 14px;
`;

export const InputWithWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: IBM Plex Sans;
  gap: 4px;
  width: 100%;
  position: relative;
  border-radius: 4px;
  background: #def1ff;
  padding: 6px 8px;

  & > label:not(:first-child) {
    margin-top: 12px;
  }

  .error {
    color: red;
    font-size: 14px;
    font-weight: 300;
    margin-top: 4px;
  }

  &.change-pass {
    margin-bottom: auto;
  }
`;

export const InputWithWrapperLabel = styled.label`
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 400;
`;

interface InputWithWrapperProps {
  error?: boolean;
}
export const InputWithWrapper = styled.input<InputWithWrapperProps>`
  border: none;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 0.5px solid #bfe0f9;
  padding-bottom: 4px;
  background: transparent;
  ${({ error }) => error && 'border-bottom: 0.5px solid red;'}
`;

export const ChangePasswordButton = styled.button`
  display: flex;
  border: none;
  color: #1a99f4;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 300;
  text-decoration-line: underline;
  background: transparent;
  margin-left: 10px;
  cursor: pointer;
  width: fit-content;
`;

export const ExcludeButton = styled.button`
  font-family: IBM Plex Sans;
  color: var(--gray-shades-dark-shade, #1f1f22);
  font-size: 14px;
  font-weight: 300;
  text-decoration-line: underline;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-top: auto;
  width: fit-content;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  gap: 10px;
`;
export const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  gap: 4px;
  border: none;
  color: #1f1f22;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
`;
