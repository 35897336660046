import Icon from '../Icon';
import * as S from './styles';

export function WppSupport() {
  return (
    <S.WppContainer
      href="https://api.whatsapp.com/send?phone=5511968507722"
      target="_blank"
      rel="noreferrer"
    >
      <p>Suporte</p>
      <Icon type="wpp" />
    </S.WppContainer>
  );
}

export default WppSupport;
