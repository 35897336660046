import styled from 'styled-components';

export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 2px;
  padding: 12px;

  span {
    color: rgba(0, 0, 0, 0.6);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }

  & > button {
    margin-top: 12px;
    color: #b18282;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    font-family: Montserrat;
    justify-content: center;
    padding: 4px 12px;
    align-items: center;
    border-radius: 6px;
    border: 0.5px solid #def1ff;
    background: #bfe0f9;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  }
`;
