import { useEffect } from 'react';
import { startCheckPaymentJob } from '../utils/paymentUtils';
import { TransactionIdsProps, UserProps } from '../context/types';

export const usePayment = (
  user: UserProps,
  transactionIds: TransactionIdsProps[],
  setTransactionIds: React.Dispatch<
    React.SetStateAction<TransactionIdsProps[]>
  >,
  updateUserInfo: <T extends keyof UserProps>(
    fieldName: T,
    newValue: UserProps[T]
  ) => void,
  setModalScreen: (screen: string) => void
) =>
  useEffect(() => {
    const initJob = transactionIds.some(
      ({ already_confirmed, cancelled }) => !already_confirmed && !cancelled
    );

    if (initJob)
      startCheckPaymentJob(
        user.id,
        transactionIds,
        setTransactionIds,
        updateUserInfo,
        setModalScreen
      );
  }, [
    setModalScreen,
    setTransactionIds,
    transactionIds,
    updateUserInfo,
    user.id,
  ]);
