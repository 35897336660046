import { useCallback, useContext, useEffect } from 'react';
import { MachineContext } from '../../../context';
import { isMobileDevice } from '../../../utils';

import * as S from './styles';

export function CatchButton() {
  const {
    status,
    socket,
    moved,
    plays,
    feedback,
    seconds,
    videoIsLoaded,
    isActive,
    endGame,
    user,
  } = useContext(MachineContext);

  const allowCommand =
    status === 'P' &&
    moved &&
    plays &&
    feedback === '' &&
    videoIsLoaded &&
    isActive;

  const cancelCommand = useCallback(() => {
    if (socket && socket.connected && allowCommand) {
      socket.emit('command', {
        button: 'C',
        command: 1,
        username: user.name,
      });
      setTimeout(() => {
        socket.emit('command', {
          button: 'C',
          command: 0,
          username: user.name,
        });
      }, 50);
      endGame();
    }
  }, [allowCommand, endGame, socket, user.name]);

  useEffect(() => {
    if (!isActive || seconds > 0) return;

    if (socket && socket.connected) {
      if (!moved) {
        socket.emit('game_timeout', true);
      } else {
        socket.emit('command', {
          button: 'C',
          command: 1,
          username: user.name,
        });
        setTimeout(() => {
          socket.emit('command', {
            button: 'C',
            command: 0,
            username: user.name,
          });
        }, 50);
      }
    }

    endGame();
  }, [isActive, seconds, moved, socket, endGame, user.name]);

  return (
    <S.CatchButton
      disabled={
        status !== 'P' ||
        seconds === 0 ||
        !plays ||
        !moved ||
        !isActive ||
        !videoIsLoaded
      }
      {...(isMobileDevice() && {
        onTouchEnd: cancelCommand,
      })}
      {...(!isMobileDevice() && {
        onMouseUp: cancelCommand,
      })}
    >
      Capturar
    </S.CatchButton>
  );
}

export default CatchButton;
