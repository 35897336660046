import styled from 'styled-components';
import BackButton from '../../atoms/BackButton';
import { Device } from '../../../styles/device';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 69.25px);

  @media ${Device.Tablet} {
    height: calc(100dvh - 120px);
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;

  h2 {
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
  }

  span {
    color: rgba(0, 0, 0, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  flex: 1;
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 14px;
  border-radius: 2px;
  opacity: 0.8;
  background: #def1ff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &:hover {
    opacity: 1;
  }

  span {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
  }

  h3 {
    font-size: 20px;
  }
`;

export const StyledBackButton = styled(BackButton)`
  margin-left: 14px;
  margin-top: 24px;
`;

export const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  gap: 4px;
  border: none;
  color: #1f1f22;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  align-self: flex-start;
`;
