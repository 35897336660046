import * as S from './styles';
import { InformationSpanProps } from './types';

export function InformationSpan({
  color,
  size,
  children,
  padding,
  flex,
}: InformationSpanProps) {
  return (
    <S.Information color={color} size={size} padding={padding} flex={flex}>
      {children}
    </S.Information>
  );
}

export default InformationSpan;
