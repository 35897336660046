import styled from 'styled-components';

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: fit-content;
  gap: 4px;

  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  span {
    color: #4978f0;
  }
`;
