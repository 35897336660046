import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-bottom: auto;
`;

export const ChooseValueDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  max-width: fit-content;

  & > div {
    padding: 4px 8px;
    border-bottom: 0.5px solid #a4a4a9;
  }

  span {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
  }

  input {
    border: none;
    background: none;
    color: #757171;
    font-family: IBM Plex Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.2px;
    opacity: 1;
    max-width: 40px;
  }
`;

interface MenuItemProps {
  shadow?: boolean;
}
export const MenuItem = styled.div<MenuItemProps>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 14px;
  border-radius: 2px;
  opacity: 0.8;
  background: #def1ff;
  ${({ shadow }) =>
    shadow && 'box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);'}
  width: 100%;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;

    &:first-child {
      cursor: pointer;
    }
  }

  & > div > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &:hover {
    opacity: 1;
  }

  span {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 14px 14px 0;
  color: #111;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  gap: 8px;

  & > div {
    gap: 8px;
    display: flex;
    align-items: center;
  }

  & > div:nth-child(2) {
    flex: 1;
  }

  legend {
    height: unset;

    span {
      opacity: 1;
    }
  }

  @media (max-width: 350px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    & > div:nth-child(2) {
      width: 100px;
    }
  }
`;
