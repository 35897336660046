import { useContext } from 'react';
import { MachineContext } from '../../../context';
import InformationSpan from '../../atoms/InformationSpan';
import * as S from './styles';
import { WaitingDisplayProps } from './types';

export function WaitingDisplay({ className, show }: WaitingDisplayProps) {
  const { user } = useContext(MachineContext);
  return (
    <S.InfoContainer className={className} show={show}>
      <InformationSpan color="#b18282" size="22px">
        {!user.isLogged && 'Entre para jogar'}
      </InformationSpan>
    </S.InfoContainer>
  );
}

export default WaitingDisplay;
