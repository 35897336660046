import styled from 'styled-components';

interface DirectionalButtonProps {
  disabled: boolean;
}
export const DirectionalButton = styled.button<DirectionalButtonProps>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #a6d4f5;
  color: #b18282;
  font-size: 16px;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  ${({ disabled }) => disabled && 'opacity: 0.5;'}

  &:active {
    background-color: #ccc;
  }

  @media (max-width: 326px) {
    width: 45px;
    min-width: 45px;
    height: 45px;
    min-height: 45px;
    svg {
      width: 15px;
      height: auto;
    }
  }
`;
