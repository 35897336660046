import styled from 'styled-components';
import blueBack from '../../../assets/blue_back.svg';
import BackButton from '../../atoms/BackButton';
import { Device } from '../../../styles/device';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100dvh - 80px);

  @media ${Device.Tablet} {
    height: unset;
    margin: auto;
    justify-content: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  gap: 20px;
  flex: 1;

  a {
    align-self: center;
  }

  @media ${Device.Tablet} {
    max-width: 728px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  height: 80%;

  h2 {
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    flex: 1;
  }

  span {
    color: rgba(0, 0, 0, 0.7);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 133.4%; /* 21.344px */
    flex: 1;
  }
`;

export const ImageContainer = styled.div`
  display: block;
  background-image: url(${blueBack});
  padding-top: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: calc(0.6933 * 100vw);
  margin-top: -1px;

  @media ${Device.Tablet} {
    min-height: auto;
    max-width: 728px;
    background: none;
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
  gap: 20px;

  span {
    color: rgba(0, 0, 0, 0.7);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 133.4%;
  }

  @media ${Device.Tablet} {
    justify-content: flex-start;
  }
`;

export const StyledBackButton = styled(BackButton)`
  margin-left: 14px;
`;

export const ContactContainer = styled.div`
  border-top: 2px solid #bfe0f9;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-top: 20px;
  justify-content: center;
  gap: 12px;
  font-family: Poppins;

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .blue {
    color: #007bff;
    text-decoration: none;
  }

  span {
    word-break: break-word;
  }
`;

export const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  align-self: center;

  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
