import { useContext } from 'react';
import { MachineContext } from '../../../context';
import * as S from './styles';
import { MenuDrawerProps } from './types';

export function MenuDrawer({ children }: MenuDrawerProps) {
  const { showDesktopMenu } = useContext(MachineContext);
  return <S.Wrapper showMenu={showDesktopMenu}>{children}</S.Wrapper>;
}

export default MenuDrawer;
