import styled from 'styled-components';

export const InputWithWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: IBM Plex Sans;
  gap: 4px;
  width: 100%;
  position: relative;
  border-radius: 4px;
  background: #def1ff;
  padding: 12px 24px;

  svg {
    position: absolute;
    right: 8px;
    top: 2px;
    cursor: pointer;
  }

  .error {
    color: red;
    font-size: 10px;
    font-weight: 300;
    margin-top: 4px;
  }
`;

export const InputWithWrapperLabel = styled.label`
  color: #111;
  font-weight: 400;
  font-size: 16px;
`;

interface InputWithWrapperProps {
  error?: boolean;
}
export const InputWithWrapper = styled.input<InputWithWrapperProps>`
  border: none;
  font-family: IBM Plex Sans;
  padding: 12px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  background: var(--surface-light, #f8fafc);
  ${({ error }) => error && 'border-bottom: 0.5px solid red;'}
`;
