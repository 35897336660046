import * as S from './styles';

import timeover from '../../../assets/timeover.gif';
import machineError from '../../../assets/machine-error.svg';

export function FeedbackContainer({ type }: { type: 'error' | 'finished' }) {
  if (type === 'error') {
    return (
      <S.FeedbackContainer>
        <img src={machineError} alt="machine_error" />
        <S.TitleContainer>
          <h2>Máquina em abastecimento</h2>
          <h4>
            Devolvemos o valor das jogadas que não foram concluídas, tente
            novamente em alguns instantes.
          </h4>
          <h4>Você será redirecionado automaticamente para a tela inicial</h4>
        </S.TitleContainer>
      </S.FeedbackContainer>
    );
  }

  return (
    <S.FeedbackContainer>
      <img src={timeover} alt="time_over" />
      <S.TitleContainer>
        <h2>Suas jogadas acabaram</h2>
      </S.TitleContainer>
    </S.FeedbackContainer>
  );
}

export default FeedbackContainer;
