import styled from 'styled-components';
import Alert from '../../atoms/Alert';
import BackButton from '../../atoms/BackButton';
import { Device } from '../../../styles/device';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;

  @media ${Device.Tablet} {
    max-width: 728px;
    margin: 20px auto;
    justify-content: flex-start;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  gap: 20px;
  flex: 1;

  h4 {
    color: #111;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  height: 80%;

  h2 {
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
  }

  span {
    color: rgba(0, 0, 0, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const ImageContainer = styled.div`
  display: block;
  width: 100%;
  margin-top: -1px;
`;

interface MenuItemProps {
  shadow?: boolean;
}
export const MenuItem = styled.div<MenuItemProps>`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px;
  border-radius: 2px;
  cursor: pointer;
  opacity: 0.8;
  background: #def1ff;
  ${({ shadow }) =>
    shadow && 'box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);'}
  width: 100%;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &:hover {
    opacity: 1;
  }

  span {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
  }

  h3 {
    font-size: 20px;
  }
`;

export const StyledAlert = styled(Alert)`
  margin: auto 0;

  span {
    text-decoration-line: none;
  }
`;

export const ResumeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  flex: 1;

  h4 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
  }

  h5 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
  }

  .total {
    display: flex;
    gap: 8px;
    margin-bottom: auto;
  }
`;

export const StyledBackButton = styled(BackButton)`
  margin-left: 14px;
`;

export const ChooseValueLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 18px;
  margin-left: 10px;
  max-width: fit-content;

  span {
    color: rgba(0, 0, 0, 0.8);
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.24px;
  }

  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #bfe0f9;
  }

  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #bfe0f9;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #bfe0f9;
  }
`;
