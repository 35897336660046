import styled from 'styled-components';

export const ResumeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  flex: 1;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    padding: 0 32px;
  }

  h4 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
  }

  h5 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
  }

  span {
    display: block;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  p {
    display: flex;
    align-items: center;
    color: #000;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 400;
  }

  .total {
    display: flex;
    gap: 8px;
    margin-bottom: auto;
  }

  button {
    margin-top: 48px;
  }
`;
