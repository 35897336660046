import { api } from './api';

const paymentService = {
  getPaymentStatus: async (transactionId: string) => {
    const request = await api.get<{
      already_confirmed: boolean;
      cancelled: boolean;
    }>(`/internal/payment/check`, { params: { charge_id: transactionId } });

    return request.data;
  },
};

export default paymentService;
