import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { BackButtonProps } from './types';
import { BsChevronLeft } from 'react-icons/bs';

export function BackButton({
  onClick,
  className,
  showInitialButton,
  isSamePage,
}: BackButtonProps) {
  const navigate = useNavigate();

  const handleBackRedirect = () => {
    onClick?.();
    if (isSamePage) return;
    navigate(-1);
  };

  return (
    <>
      <S.BackButtonContainer className={className}>
        <div onClick={handleBackRedirect}>
          <BsChevronLeft size={16} />
          <span>Voltar</span>
        </div>
        {showInitialButton && (
          <div onClick={() => navigate('/')}>
            /<span>Início</span>
          </div>
        )}
      </S.BackButtonContainer>
    </>
  );
}

export default BackButton;
