import styled from 'styled-components';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-bottom: auto;
`;

interface DoubleColumnProps {
  limitSizeOn?: number;
  maxWidth?: number;
}
export const DoubleColumn = styled.div<DoubleColumnProps>`
  display: flex;
  gap: 20px;
  width: 100%;

  & > div:nth-child(${({ limitSizeOn }) => limitSizeOn}) {
    max-width: ${({ maxWidth }) => maxWidth}px;
  }
`;
