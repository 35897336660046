import styled from 'styled-components';
import Button from '../../atoms/Button';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  width: 100%;
  height: 100%;

  h3 {
    color: #78cc1f;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 133.4%; /* 29.348px */
  }

  span {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.4%; /* 24.012px */
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

export const StyledButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
`;
