import * as S from './styles';
import { colors } from '../../../styles/colors';

import { useContext, useState } from 'react';
import Button from '../../atoms/Button';
import { MachineContext } from '../../../context';
import { errorHandler } from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import userService from '../../../services/userService';
import { VscReactions } from 'react-icons/vsc';
import { MenuItem, Select } from '@mui/material';

export function Exchange() {
  const { user, setModalScreen, updateUserInfo } = useContext(MachineContext);
  const [plushQtd, setPlushQtd] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const onHandleClick = async () => {
    try {
      setIsLoading(true);
      await userService.postExchangePlush(user.id, plushQtd);
      await userService.getWallet(user.id).then((response) => {
        updateUserInfo('amount', response.available_money);
        updateUserInfo('plushQtd', response.plush_to_recover);
        updateUserInfo('victoryAmount', response.victory_money);
      });
      setModalScreen('exchange');
      navigate('/');
    } catch (error) {
      alert(errorHandler(error));
    } finally {
      setIsLoading(false);
    }
  };

  const limit = user.plushQtd <= 5 ? user.plushQtd : 5;

  const options = Array.from({ length: limit }, (_, index) => (
    <MenuItem
      key={index}
      selected={plushQtd === index + 1}
      onClick={() => setPlushQtd(index + 1)}
      value={index + 1}
    >
      {index + 1}
    </MenuItem>
  ));

  return (
    <>
      <S.Container>
        <S.SelectContainer>
          <div>
            <VscReactions size={24} />
            <span>Selecionar pelúcias</span>
          </div>
          {options.length > 0 && (
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={plushQtd.toString() === '0' ? '' : plushQtd.toString()}
              onChange={(e) => setPlushQtd(Number(e.target.value))}
              label="Quantidade"
            >
              {options}
            </Select>
          )}
        </S.SelectContainer>
        <S.MenuItem shadow>
          <h4>Valor a ser enviado à carteira</h4>
          <S.ChooseValueDiv>
            <div>
              <span>
                R${' '}
                <input
                  type="number"
                  value={user.plushPrice * plushQtd}
                  disabled
                />
              </span>
            </div>
          </S.ChooseValueDiv>
        </S.MenuItem>
      </S.Container>
      <Button
        onClick={onHandleClick}
        disabled={isLoading || plushQtd === 0}
        color={colors.blue}
      >
        Finalizar Resgate
      </Button>
    </>
  );
}

export default Exchange;
