import styled from 'styled-components';
import { Device } from '../../../styles/device';

interface HeaderProps {
  backgroundColor?: string;
}

export const BetaContainer = styled.div`
  position: relative !important;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0 !important;

  img {
    position: absolute;
    right: -30px;
    top: -14px;
  }
`;

export const Header = styled.header<HeaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ backgroundColor }) => backgroundColor || '#fff'};
  padding: 0 20px;
  justify-content: center;
  min-height: 69.25px;
  overflow: hidden;
  position: relative;

  ${BetaContainer} {
    color: #2b3045;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    font-family: Wendy One;
    line-height: 133.4%; /* 29.348px */
    margin: auto 20px;
    text-align: center;

    @media ${Device.Tablet} {
      font-size: 28px;
      font-weight: 700;
    }
  }

  & > div:first-child {
    position: absolute;
    left: 20px;
    cursor: pointer;
    z-index: 1;

    & > svg {
      @media ${Device.Tablet} {
        height: 70px;
        width: 70px;
      }
    }
  }

  & > svg {
    position: absolute;
    top: -3px;
    z-index: 0;
    animation: move 10s ease-in-out infinite;

    @media ${Device.Tablet} {
      height: 100px;
      width: auto;
    }
  }

  @keyframes move {
    0%,
    100% {
      left: 0;
    }
    50% {
      left: calc(100% - 55px);
    }
  }

  @media (max-width: 410px) {
    ${BetaContainer} {
      h3 {
        font-size: 22px;
      }
    }
  }

  @media ${Device.Tablet} {
    height: 120px;
  }
`;
