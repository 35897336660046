import gameService from '../services/gameService';

const interval = 10 * 1000;

export const startGetPlayerStatusJob = (
  userId: string,
  setStatus: (status: 'O' | 'W' | 'R' | 'P') => void,
  setWaitingList: (position: number) => void,
  setGameToken: React.Dispatch<React.SetStateAction<string>>,
  setCurrentPlayerName: React.Dispatch<React.SetStateAction<string>>,
  setPlays: React.Dispatch<React.SetStateAction<number>>
) => {
  const getStatus = async () => {
    try {
      const response = await gameService.getGameStatus(userId);
      setPlays(response.moves);
      if (
        response.position === 0 &&
        response.game_token &&
        response.game_status === 'ready_to_play'
      ) {
        setStatus('R');
        setGameToken(response.game_token);
        clearInterval(intervalId);
      } else {
        setWaitingList(response.position);
        setCurrentPlayerName(response.current_player_name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  getStatus();
  const intervalId = setInterval(async () => {
    getStatus();
  }, interval);
};
