import * as S from './styles';
import Icon from '../../atoms/Icon';
import { HeaderProps } from './types';
import { useNavigate, useLocation } from 'react-router-dom';
import { FcSettings } from 'react-icons/fc';
import { useMediaQuery } from 'react-responsive';
import { useContext, useEffect } from 'react';
import { MachineContext } from '../../../context';
import beta from '../../../assets/beta.svg';

export function Header({ backgroundColor, showMenu }: HeaderProps) {
  const { showDesktopMenu, setShowDesktopMenu } = useContext(MachineContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const onMenuClick = () => {
    if (isDesktop) {
      setShowDesktopMenu(!showDesktopMenu);
    } else {
      navigate(location.pathname === '/menu' ? '/' : '/menu');
    }
  };

  useEffect(() => {
    if (!isDesktop) {
      setShowDesktopMenu(false);
    }
  }, [isDesktop, setShowDesktopMenu]);

  return (
    <S.Header backgroundColor={backgroundColor}>
      {showMenu && (
        <div onClick={onMenuClick}>
          <FcSettings size={38} />
        </div>
      )}
      <S.BetaContainer>
        <h3>Online Machine</h3>
        <img src={beta} alt="beta" />
      </S.BetaContainer>
      <Icon type="garra" />
    </S.Header>
  );
}

Header.defaultProps = {
  showMenu: true,
};

export default Header;
