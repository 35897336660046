import * as S from './styles';
import { InstructionsProps } from './types';

export function Instructions({ onClose, showSecondScreen }: InstructionsProps) {
  return (
    <S.InstructionsContainer>
      {showSecondScreen && (
        <span>
          Quando chegar a sua vez, use os botões azuis para se movimentar, o
          botão amarelo para capturar a pelúcia. O jogo termina após usar o
          botão amarelo ou finalizar o tempo, o que ocorrer primeiro.
        </span>
      )}
      {!showSecondScreen && (
        <>
          <span>Cada jogada tem duração de 30 segundos.</span>
          <span>
            Se ao entrar na fila você selecionou 2 jogadas ou mais, o tempo será
            somado e você fará múltiplas jogadas consecutivas com intervalo de
            10 segundos entre elas.
          </span>
        </>
      )}

      <button onClick={() => onClose()}>Fechar</button>
    </S.InstructionsContainer>
  );
}

export default Instructions;
