import styled from 'styled-components';

interface InputProps {
  error?: boolean;
  paddingRight?: boolean;
}
export const InputWrapper = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  font-family: IBM Plex Sans;
  width: 100%;
  position: relative;

  svg {
    position: absolute;
    right: 8px;
    bottom: ${({ error }) => (error ? '19px' : '2px')};
    cursor: pointer;
  }

  .error {
    color: red;
    font-size: 10px;
    font-weight: 300;
    margin-top: 4px;
  }
`;

export const InputLabel = styled.label`
  color: #6f7482;
  font-weight: 400;
  font-size: 14px;
`;

export const Input = styled.input<InputProps>`
  border: none;
  border-bottom: 0.5px solid #000;
  font-family: IBM Plex Sans;
  margin-top: 4px;
  ${({ paddingRight }) => paddingRight && 'padding-right: 40px;'}
  font-size: 18px;
  font-weight: 300;
  ${({ error }) => error && 'border-bottom: 0.5px solid red;'}
`;
