import * as S from './styles';
import { colors } from '../../../styles/colors';

import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { MachineContext } from '../../../context';
import paymentIcon from '../../../assets/payment_confirm.svg';

export function RescueMoneySuccess() {
  const navigate = useNavigate();
  const { setModalScreen } = useContext(MachineContext);

  const onHandleJoin = () => {
    setModalScreen('');
    navigate('/join');
  };

  return (
    <S.Content>
      <div>
        <h3>Recebemos a sua solicitação de resgate</h3>
        <span>
          Em até 2 dias úteis você receberá o valor referente a sua pelúcia
          através da chave PIX indicada.
        </span>
      </div>

      <S.StyledImage src={paymentIcon} alt="payment confirm" />
      <S.StyledButton onClick={onHandleJoin} color={colors.blue}>
        Entrar na fila
      </S.StyledButton>
    </S.Content>
  );
}

export default RescueMoneySuccess;
