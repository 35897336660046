import Button from '../../atoms/Button';
import Input from '../../atoms/NewInput';
import LoginForm from '../../organisms/LoginForm';
import { useEffect, useState, useContext } from 'react';
import { RegisterFormProps } from './types';

import * as S from './styles';
import userService from '../../../services/userService';
import { errorHandler } from '../../../services/api';
import {
  validateCpf,
  validateNickname,
  validateStrongPassword,
} from '../../../utils';
import { MachineContext } from '../../../context';
import { Alert } from '@mui/material';

export function RegisterForm({ email, prevStep }: RegisterFormProps) {
  const { setModalScreen } = useContext(MachineContext);
  const [password, setPassword] = useState('');
  const [mailCode, setMailCode] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [cpf, setCpf] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passHasError, setPassHasError] = useState(false);
  const [accept, setAccept] = useState(false);
  const [userNameHasError, setUserNameHasError] = useState('');
  const [cpfHasError, setCpfHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setPasswordError('');
    setPassHasError(false);
    setCpfHasError(false);
    setUserNameHasError('');
  }, [password, repeatPassword, cpf, userName]);

  const onHandleClick = async () => {
    const invalidNicknameReason = validateNickname(userName);
    if (invalidNicknameReason) {
      setUserNameHasError(invalidNicknameReason);
      return;
    }

    const invalidPasswordReason = validateStrongPassword(password);
    if (invalidPasswordReason) {
      setPasswordError(invalidPasswordReason);
      return;
    }

    if (!validateCpf(cpf)) {
      setCpfHasError(true);
      return;
    }

    if (password !== repeatPassword) {
      setPassHasError(true);
      return;
    }

    setIsLoading(true);
    const cpfNumbers = cpf.replace(/\D/g, '');

    try {
      await userService.postRegister({
        access_code: mailCode,
        cpf: cpfNumbers.toString(),
        email,
        name: userName,
        password,
      });
      prevStep();
    } catch (error) {
      alert(errorHandler(error));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (seconds) {
      const interval = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds]);

  const onHandleResendCode = async () => {
    if (seconds) return;
    try {
      await userService.postResendCode(email, 'validate_account');
      setSeconds(30);
    } catch (error) {
      alert(errorHandler(error));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  }, []);

  return (
    <S.Container>
      <h4 style={{ margin: '16px 15px 0' }}>{email}</h4>
      <S.StyledInputWithWrapper
        type="text"
        placeholder="** ** ** **"
        label="Insira o código que enviamos para o seu e-mail"
        name="mailCode"
        value={mailCode}
        onChange={setMailCode}
      >
        <S.StyledButton onClick={onHandleResendCode} disabled={!!seconds}>
          Reenviar Código{' '}
          {seconds
            ? `00:${seconds.toLocaleString('pt-BR', {
                minimumIntegerDigits: 2,
              })}`
            : ''}
        </S.StyledButton>
      </S.StyledInputWithWrapper>
      {open && (
        <Alert
          onClose={() => setOpen(false)}
          severity="info"
          variant="filled"
          sx={{ margin: '0 15px' }}
        >
          Se você não receber o e-mail em alguns minutos, verifique sua pasta de
          spam ou lixo eletrônico.
        </Alert>
      )}
      <LoginForm>
        <Input
          type="text"
          placeholder="Apelido"
          name="userName"
          errorMessage={userNameHasError}
          hasError={!!userNameHasError}
          value={userName}
          onChange={setUserName}
        />
        <Input
          type="text"
          placeholder="CPF"
          errorMessage="CPF inválido"
          hasError={cpfHasError}
          name="cpf"
          value={cpf}
          onChange={setCpf}
        />
        <Input
          type="password"
          placeholder="Informe uma senha"
          name="password"
          errorMessage={passwordError}
          hasError={!!passwordError}
          value={password}
          onChange={setPassword}
        />
        <Input
          type="password"
          placeholder="Repita a senha"
          name="repeatPassword"
          value={repeatPassword}
          onChange={setRepeatPassword}
          errorMessage="As senhas não coincidem"
          hasError={passHasError}
        />
        <S.AcceptTerms>
          <input
            type="radio"
            id="term"
            name="term"
            checked={accept}
            onClick={() => setAccept(!accept)}
          />
          <span>
            Li e aceito os{' '}
            <button onClick={() => setModalScreen('policy')}>
              termos de serviço e política de privacidade.
            </button>
          </span>
        </S.AcceptTerms>
        <Button
          color="#56A8E7"
          border={false}
          onClick={() => {
            onHandleClick();
          }}
          disabled={
            passHasError ||
            cpfHasError ||
            !password ||
            !cpf ||
            !repeatPassword ||
            !accept ||
            !mailCode ||
            !userName ||
            isLoading
          }
        >
          CADASTRAR
        </Button>
      </LoginForm>
    </S.Container>
  );
}

export default RegisterForm;
