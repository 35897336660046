import CatchButton from '../../atoms/CatchButton';
import DirectionalButton from '../../atoms/DirectionalButton';
import Icon from '../../atoms/Icon';

import * as S from './styles';

export function ButtonsContainer() {
  return (
    <S.ButtonsContainer>
      <CatchButton />
      <S.DirecionalContainer>
        <DirectionalButton command="L">
          <Icon type="left" />
        </DirectionalButton>
        <DirectionalButton command="U">
          <Icon type="up" />
        </DirectionalButton>
        <DirectionalButton command="D">
          <Icon type="down" />
        </DirectionalButton>
        <DirectionalButton command="R">
          <Icon type="right" />
        </DirectionalButton>
      </S.DirecionalContainer>
    </S.ButtonsContainer>
  );
}

export default ButtonsContainer;
