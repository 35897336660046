import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  color: rgba(0, 0, 0, 0.7);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;

  svg {
    height: 24px;
    min-width: 24px;
  }
`;
