import userService from '../services/userService';
import {
  getOlmsCookie,
  removeAuthCookie,
  removeOlmsCookie,
} from './cookieUtils';

export const checkAuth = async () => {
  const olmsCookie = getOlmsCookie();
  if (!olmsCookie) {
    return;
  }

  try {
    const tokenResponse = await userService.getToken(olmsCookie);
    if (!tokenResponse.user_has_valid_cookie) {
      removeAuthCookie();
      removeOlmsCookie();
      return;
    }

    const response = await userService.getAuth(tokenResponse.token_string);
    if (!response.auth_token.is_token_valid) {
      removeAuthCookie();
      return;
    }

    return response;
  } catch (error) {
    removeAuthCookie();
  }
};
