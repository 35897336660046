import styled from 'styled-components';
import BackButton from '../../atoms/BackButton';
import { Device } from '../../../styles/device';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100dvh - 80px);
  margin-top: 10px;

  @media ${Device.Tablet} {
    height: unset;
    max-width: 728px;
    margin: 20px auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  gap: 20px;
  flex: 1;

  h4 {
    color: #111;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
  }

  & > button {
    margin-top: auto;
  }

  @media ${Device.Tablet} {
    flex: unset;

    & > button {
      align-self: flex-end;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  height: 80%;

  h2 {
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
  }

  span {
    color: rgba(0, 0, 0, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
  }

  p {
    margin-top: 10px;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 800;
    -webkit-text-stroke: 0.3px black;
  }
`;

export const ImageContainer = styled.div`
  display: block;
  width: 100%;
  margin-top: -1px;
`;

interface MenuItemProps {
  shadow?: boolean;
}
export const MenuItem = styled.div<MenuItemProps>`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px;
  border-radius: 2px;
  opacity: 0.8;
  background: #def1ff;
  ${({ shadow }) =>
    shadow && 'box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);'}
  width: 100%;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &:hover {
    opacity: 1;
  }

  span {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
  }

  h3 {
    font-size: 20px;
  }
`;

export const ResumeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  flex: 1;

  h4 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
  }

  h5 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
  }

  .total {
    display: flex;
    gap: 8px;
    margin-bottom: auto;
  }
`;

export const StyledBackButton = styled(BackButton)`
  margin-left: 14px;
`;

export const SelectContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 14px 14px 0;
  color: #111;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  gap: 8px;

  & > div {
    gap: 8px;
    display: flex;
    align-items: center;
  }

  & > div:nth-child(2) {
    flex: 1;
  }

  legend {
    height: unset;

    span {
      opacity: 1;
    }
  }

  @media (max-width: 350px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    & > div:nth-child(2) {
      width: 100px;
    }
  }
`;

export const NoOptions = styled.div`
  color: #111;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;

  button {
    border: none;
    background: transparent;
    color: #2196f3;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

export const UseVictoryMoney = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px;

  span {
    color: #111;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
  }
`;
