import Icon from '../../atoms/Icon';
import * as S from './styles';
import { LoginFormProps } from './types';

export function LoginForm({ children }: LoginFormProps) {
  return (
    <>
      <S.LoginForm>{children}</S.LoginForm>
      <S.RedirectContainer>
        <a href="https://www.alabuta.com/" target="_blank" rel="noreferrer">
          <Icon type="signature" />
        </a>
      </S.RedirectContainer>
    </>
  );
}

export default LoginForm;
