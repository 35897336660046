import styled from 'styled-components';
import blueBack from '../../../assets/blue_back.svg';
import BackButton from '../../atoms/BackButton';
import { Device } from '../../../styles/device';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100dvh - 69.25px);

  @media ${Device.Tablet} {
    max-width: 728px;
    margin: 20px auto;
    justify-content: flex-start;
    height: unset;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  gap: 20px;
  flex: 1;

  @media ${Device.Tablet} {
    flex: unset;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  height: 80%;

  h2 {
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 40px;

    span {
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
  }
`;

export const ImageContainer = styled.div`
  display: block;
  background-image: url(${blueBack});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(0.6933 * 100vw);
  margin-top: -1px;

  @media ${Device.Tablet} {
    min-height: auto;
    background: none;
    height: unset;
  }
`;

export const StyledBackButton = styled(BackButton)`
  margin-left: 14px;
`;

export const StepsContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  & > div {
    color: #2196f3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    min-width: 40px;
    height: 40px;
    border-radius: 2px;
    background: #def1ff;
  }

  span {
    color: #2196f3;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const PixInput = styled.input`
  display: flex;
  width: 100%;
  height: 52px;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2px;
  border: 2px solid #def1ff;
  color: rgba(0, 0, 0, 0.6);
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
`;

export const PaymentInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 1;
    gap: 20px;
    display: flex;
    flex-direction: column;

    @media ${Device.Tablet} {
      margin-top: 20px;
    }
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 100%;

  span {
    border-top: 1px solid #bfe0f9;
    padding-top: 10px;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
  }

  div {
    display: flex;
    gap: 20px;
    align-items: center;
  }
`;

export const RedirectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 16px;
  margin-top: 20px;

  @media ${Device.Tablet} {
    display: none;
  }
`;
