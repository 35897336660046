import { Home } from '../components/pages/Home';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../components/pages/Login';
import Menu from '../components/pages/Menu';
import Wallet from '../components/pages/Wallet';
import Rescue from '../components/pages/Rescue';
import About from '../components/pages/About';
import JoinGame from '../components/pages/JoinGame';
import Checkout from '../components/pages/Checkout';
import Account from '../components/pages/Account';
import ForgotPass from '../components/pages/ForgotPass';

const ComponentRoutes: React.FunctionComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Login />} path="/login" />
        <Route element={<Menu />} path="/menu" />
        <Route element={<Wallet />} path="/wallet" />
        <Route element={<Rescue />} path="/rescue" />
        <Route element={<About />} path="/about" />
        <Route element={<JoinGame />} path="/join" />
        <Route element={<Checkout />} path="/checkout/:amount" />
        <Route element={<Account />} path="/account" />
        <Route element={<ForgotPass />} path="/recover" />
      </Routes>
    </BrowserRouter>
  );
};

export default ComponentRoutes;
