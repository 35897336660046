import styled from 'styled-components';
import { Device } from '../../../styles/device';

interface ButtonProps {
  color?: string;
  border?: boolean;
}
export const SubmitButton = styled.button<ButtonProps>`
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  gap: 6px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: ${({ border }) => (border ? '1px solid #def1ff' : 'none')};
  background: ${({ color }) => (color ? color : '#bfe0f9')};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  color: ${({ color }) => (color === '#56A8E7' ? '#fff' : '#b18282')};
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;

  @media ${Device.Tablet} {
    font-size: 18px;
    max-width: fit-content;
  }
`;
