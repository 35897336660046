import * as S from './styles';
import Header from '../../molecules/Header';
import { colors } from '../../../styles/colors';
import { removeAuthCookie, removeOlmsCookie } from '../../../utils/cookieUtils';

import {
  VscWindow,
  VscChevronRight,
  VscSettingsGear,
  VscGift,
} from 'react-icons/vsc';
import Icon from '../../atoms/Icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { MachineContext } from '../../../context';
import WppSupport from '../../atoms/WppSupport';
import { useMediaQuery } from 'react-responsive';

export function Menu() {
  const { user, updateUserInfo } = useContext(MachineContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const redirectTo = (path: string) => {
    navigate(path);
  };
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const onHandleLogout = () => {
    removeAuthCookie();
    removeOlmsCookie();
    updateUserInfo('isLogged', false);
    updateUserInfo('id', '');
    updateUserInfo('name', '');
    updateUserInfo('amount', 0);
    navigate('/');
  };

  return (
    <>
      {!isDesktop && <Header backgroundColor={colors.blue} />}
      <S.Container>
        {!isDesktop && <S.StyledBackButton />}
        <S.TitleContainer>
          <h2>Menu</h2>
          {user.isLogged && <span>Olá, {user.name}</span>}
        </S.TitleContainer>
        <S.MenuItems>
          {user.isLogged && (
            <>
              <S.MenuItem onClick={() => redirectTo('/wallet')}>
                <div>
                  <VscWindow size={24} />
                  <span>Carteira</span>
                </div>
                <VscChevronRight size={24} />
              </S.MenuItem>
              <S.MenuItem onClick={() => redirectTo('/account')}>
                <div>
                  <VscSettingsGear size={24} />
                  <span>Minha conta</span>
                </div>
                <VscChevronRight size={24} />
              </S.MenuItem>
              <S.MenuItem onClick={() => redirectTo('/rescue')}>
                <div>
                  <VscGift size={24} />
                  <span>Minhas pelúcias</span>
                </div>
                <VscChevronRight size={24} />
              </S.MenuItem>
            </>
          )}
          <S.MenuItem onClick={() => redirectTo('/about')}>
            <div>
              <Icon type="garraMenu" />
              <span>Quem somos</span>
            </div>
            <VscChevronRight size={24} />
          </S.MenuItem>
          {pathname !== '/about' && <WppSupport />}
          <S.LogoutButton onClick={onHandleLogout}>
            <Icon type="logout" />
            Sair da conta
          </S.LogoutButton>
        </S.MenuItems>
      </S.Container>
    </>
  );
}

export default Menu;
