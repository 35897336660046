import styled from 'styled-components';
import { Device } from '../../../styles/device';

export const InteractScreenContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;

  & > span {
    color: #b18282;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }

  @media ${Device.Tablet} {
    justify-content: center;
  }
`;
