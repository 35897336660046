import styled from 'styled-components';

export const WppContainer = styled.a`
  display: flex;
  padding: 8px 8px 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background: rgba(95, 250, 121, 0.15);
  margin: 10px 0;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  margin-top: auto;

  p {
    color: rgba(17, 17, 17, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
`;
