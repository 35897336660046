import * as S from './styles';
import Header from '../../molecules/Header';
import { colors } from '../../../styles/colors';

import { useContext, useEffect, useState } from 'react';
import Button from '../../atoms/Button';
import { MachineContext } from '../../../context';
import userService from '../../../services/userService';
import Menu from '../Menu';
import MenuDrawer from '../../molecules/MenuDrawer';

export function Account() {
  const [hasError, setHasError] = useState(false);
  const [changePass, setChangePass] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatedPass, setRepeatedPass] = useState('');
  const { user } = useContext(MachineContext);

  useEffect(() => {
    setPassword('');
    setRepeatedPass('');
    setHasError(false);
  }, [changePass]);

  const onHandleClick = async () => {
    if (password !== repeatedPass) {
      setHasError(true);
      return;
    }

    await userService.putUpdatePassword(user.id, password);
    setChangePass(false);
  };

  return (
    <>
      <Header backgroundColor={colors.blue} />
      <MenuDrawer>
        <div>
          <Menu />
        </div>
        <div>
          <S.Container>
            <S.ImageContainer>
              <S.StyledBackButton showInitialButton />
              <S.TitleContainer>
                <h2>Minha Conta</h2>
                <span>Olá, {user.name}</span>
              </S.TitleContainer>
            </S.ImageContainer>
            <S.Content>
              <S.InputWithWrapperContainer>
                <S.InputWithWrapperLabel htmlFor="user">
                  USUÁRIO
                </S.InputWithWrapperLabel>
                <S.InputWithWrapper id="user" readOnly value={user.name} />
              </S.InputWithWrapperContainer>
              {!changePass && (
                <>
                  <S.InputWithWrapperContainer>
                    <S.InputWithWrapperLabel htmlFor="senha">
                      Senha
                    </S.InputWithWrapperLabel>
                    <S.InputWithWrapper
                      id="senha"
                      type="password"
                      readOnly
                      value="naovairolar"
                    />
                  </S.InputWithWrapperContainer>
                  <S.ChangePasswordButton onClick={() => setChangePass(true)}>
                    Alterar senha
                  </S.ChangePasswordButton>
                  <S.ButtonsWrapper>
                    <S.ExcludeButton>Excluir Conta</S.ExcludeButton>
                  </S.ButtonsWrapper>
                </>
              )}
              {changePass && (
                <>
                  <S.InputWithWrapperContainer className="change-pass">
                    <S.InputWithWrapperLabel htmlFor="pass">
                      INFORME A NOVA SENHA
                    </S.InputWithWrapperLabel>
                    <S.InputWithWrapper
                      id="pass"
                      type="password"
                      placeholder="***********"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <S.InputWithWrapperLabel htmlFor="pass2">
                      REPITA A NOVA SENHA
                    </S.InputWithWrapperLabel>
                    <S.InputWithWrapper
                      id="pass2"
                      error={hasError}
                      placeholder="***********"
                      type="password"
                      value={repeatedPass}
                      onChange={(e) => setRepeatedPass(e.target.value)}
                    />
                    {hasError && (
                      <span className="error">As senhas não coincidem</span>
                    )}
                  </S.InputWithWrapperContainer>
                  <Button
                    disabled={!password || !repeatedPass}
                    onClick={onHandleClick}
                  >
                    Salvar
                  </Button>
                </>
              )}
            </S.Content>
          </S.Container>
        </div>
      </MenuDrawer>
    </>
  );
}

export default Account;
