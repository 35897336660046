import styled from 'styled-components';
import { Device } from '../../../styles/device';

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  flex: 2;
  position: relative;
  padding: 0 20px;

  img {
    width: 100%;
    height: auto;
    max-width: 100%;

    @media ${Device.Tablet} {
      width: auto;
      height: 100%;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-style: normal;
  line-height: 133.4%; /* 29.348px */
  gap: 20px;
`;
