import * as S from './styles';
import { colors } from '../../../styles/colors';

import Button from '../../atoms/Button';
import { ResumeProps } from './types';
import userService from '../../../services/userService';
import { useContext, useState } from 'react';
import { MachineContext } from '../../../context';
import { useNavigate } from 'react-router-dom';
import { errorHandler } from '../../../services/api';
import { Alert } from '@mui/material';
import correios from '../../../assets/correios.svg';

export function Resume({ resumeWordings }: ResumeProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { user, setModalScreen } = useContext(MachineContext);
  const navigate = useNavigate();
  const {
    fullName,
    CEP,
    state,
    street,
    number,
    city,
    neighborhood,
    complement,
  } = resumeWordings;

  const onHandleClick = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await userService.postRescuePlush({
        player_id: user.id,
        full_name: fullName,
        zip_code: CEP,
        state,
        street_name: street,
        number,
        city,
        neighbor: neighborhood,
        complement,
      });
      setModalScreen('rescuePlush');
      navigate('/');
    } catch (error) {
      alert(errorHandler(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Alert
        severity="info"
        variant="filled"
        sx={{
          maxWidth: '728px',
          background: 'white',
          color: 'black',
        }}
      >
        Nesta fase, o valor e prazo do frete serão informados através do nosso
        suporte, que entrará em contato com você após finalizar a solicitação.
      </Alert>
      <img
        src={correios}
        alt="correios"
        height={57}
        style={{ alignSelf: 'flex-start' }}
      />
      <S.ResumeContainer>
        <div>
          <span>{fullName}</span>
          <p>
            {street},{number}
            {complement && ` - ${complement}`}
          </p>
          <p>{CEP}</p>
          <p>
            {neighborhood}, {city}
          </p>
          <p>{state}</p>
        </div>
        <Button
          onClick={onHandleClick}
          disabled={isLoading}
          color={colors.blue}
        >
          Receber minhas pelúcias
        </Button>
      </S.ResumeContainer>
    </>
  );
}

export default Resume;
