import styled from 'styled-components';

interface CatchButtonProps {
  disabled: boolean;
}
export const CatchButton = styled.button<CatchButtonProps>`
  width: 110px;
  min-width: 110px;
  height: 110px;
  min-height: 110px;
  border-radius: 50%;
  background-color: #fae012;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: none;
  ${({ disabled }) => disabled && 'opacity: 0.5;'}

  color: #b18282;
  font-family: Montserrat Alternates;
  font-size: 17.5px;
  font-weight: 600;
  line-height: 133.4%; /* 23.345px */

  @media (max-width: 326px) {
    width: 80px;
    min-width: 80px;
    height: 80px;
    min-height: 80px;
  }
`;
