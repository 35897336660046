import { useCallback, useEffect, useState } from 'react';

export function useGameTimer(
  isActive: boolean
): [
  number,
  React.Dispatch<React.SetStateAction<number>>,
  (resetSeconds: number) => void
] {
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    if (!isActive || seconds <= 0) return;

    const timerId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [isActive, seconds]);

  const resetTimer = useCallback((resetSeconds: number) => {
    setSeconds(resetSeconds);
  }, []);

  return [seconds, setSeconds, resetTimer];
}
