import styled from 'styled-components';
import { InformationSpanProps } from './types';
import { Device } from '../../../styles/device';

export const Information = styled.span<InformationSpanProps>`
  border-radius: 6px;
  flex: ${({ flex }) => (flex ? flex : '1')};
  background: #323346;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  display: inline-flex;
  padding: ${({ padding }) => (padding ? padding : '10px')};
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 4px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 133.4%; /* 16.008px */
  color: ${({ color }) => color};
  ${({ size }) => size && `font-size: ${size};`}

  @media ${Device.Tablet} {
    border-radius: 2px;
    font-family: Montserrat Alternates;
    font-size: 16px;
  }
`;
