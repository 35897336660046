import styled from 'styled-components';

interface AnchorProps {
  disabled?: boolean;
}
export const Anchor = styled.a<AnchorProps>`
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => (props.disabled ? '#888888' : '#4978f0')};
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 300;
`;

export const ForgotPassword = styled.div`
  display: flex;
  width: 100%;
  margin-top: -16px;
`;
