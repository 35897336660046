import { getAuthCookie } from '../utils/cookieUtils';
import { api } from './api';
import * as types from './userService.types';

const userService = {
  postCheckEmail: async (email: string) => {
    const request = await api.post<types.PostCheckEmailResponse>(
      `/account/check`,
      { email }
    );

    return request.data;
  },

  putResetPassword: async (email: string, code: string, password: string) => {
    await api.put<void>(`/player/forgot/pwd`, {
      email,
      access_code: code,
      new_password: password,
    });
  },

  putUpdatePassword: async (userId: string, password: string) => {
    const config = {
      headers: {
        authorization: getAuthCookie(),
      },
    };

    await api.put<void>(
      `/player/update/pwd`,
      {
        player_id: userId,
        new_password: password,
      },
      config
    );
  },

  postLogin: async (email: string, password: string) => {
    const request = await api.post<types.PostLoginResponse>(`/player/login`, {
      email,
      password,
    });

    return request.data;
  },

  postRegister: async (postRegister: types.PostRegisterRequest) => {
    await api.post<void>(`/player/new`, postRegister);
  },

  postResendCode: async (email: string, flow: string) => {
    await api.post<void>(`/account/resend-code`, { email, flow });
  },

  postAddCredit: async (amount: number, userId: string) => {
    const config = {
      headers: {
        authorization: getAuthCookie(),
      },
    };

    const request = await api.post<types.postAddCreditResponse>(
      `/player/wallet/credit`,
      {
        money: amount,
        player_id: userId,
      },
      config
    );

    return request.data;
  },

  postRescueMoney: async (userId: string, pix: string, quantity: number) => {
    const config = {
      headers: {
        authorization: getAuthCookie(),
      },
    };

    await api.post<void>(
      `/player/rescue/money`,
      { player_id: userId, pix_key: pix, plush_quantity: quantity },
      config
    );
  },

  postRescuePlush: async (info: Record<string, string>) => {
    const config = {
      headers: {
        authorization: getAuthCookie(),
      },
    };

    const request = await api.post<{ price: number }>(
      `/player/rescue/plush`,
      info,
      config
    );

    return request.data;
  },

  postExchangePlush: async (userId: string, quantity: number) => {
    const config = {
      headers: {
        authorization: getAuthCookie(),
      },
    };

    const request = await api.post<{ price: number }>(
      `/player/exchange/plush`,
      { player_id: userId, plush_quantity: quantity },
      config
    );

    return request.data;
  },

  getWallet: async (userId: string) => {
    if (!userId)
      return {
        available_money: 0,
        plush_to_recover: 0,
        victory_money: 0,
      };
    const config = {
      headers: {
        authorization: getAuthCookie(),
      },
      params: { player_id: userId },
    };

    const request = await api.get<{
      available_money: number;
      plush_to_recover: number;
      victory_money: number;
    }>(`/player/wallet`, config);

    return request.data;
  },

  getAuth: async (token: string) => {
    const request = await api.get<types.GetAuthResponse>(`/internal/` + token);

    return request.data;
  },

  getToken: async (olms: string) => {
    const request = await api.get<{
      'token_string': string;
      'user_has_valid_cookie': boolean;
    }>('/player/' + olms, {
      withCredentials: true,
    });

    return request.data;
  },
};

export default userService;
