import styled from 'styled-components';
import blueBack from '../../../assets/blue_back.svg';
import BackButton from '../../atoms/BackButton';
import { Device } from '../../../styles/device';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100dvh - 69.25px);

  @media ${Device.Tablet} {
    height: unset;
    max-width: 728px;
    margin: 0 auto;
  }
`;

interface ImageProps {
  widthPercent: number;
}
export const ImageWrapper = styled.div<ImageProps>`
  width: 100%;
  margin-top: -65px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    height: auto;
    width: ${(props) => props.widthPercent}%;
  }
`;

export const ImageContainer = styled.div`
  display: block;
  background-image: url(${blueBack});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: calc(0.6933 * 100vw);
  margin-top: -1px;

  @media ${Device.Tablet} {
    min-height: calc(0.25 * 728px);
    background-position: bottom;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  height: 60%;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 133.4%;
    margin: auto 0;
  }
`;

export const StyledBackButton = styled(BackButton)`
  margin-left: 14px;
  align-self: flex-start;
  margin-top: 10px;
`;

export const FirstScreenImages = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10%;
  height: 190px;
  margin-top: -50px;

  img:first-child {
    align-self: flex-end;
  }

  img:last-child {
    align-self: flex-start;
  }

  @media (max-width: 353px) {
    padding: 0 10px;
  }

  @media ${Device.Tablet} {
    margin-top: -100px;
    gap: 20px;
    justify-content: flex-end;
  }
`;

export const SecondScreenImages = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -50px;
`;
