import { GlobalStyle } from './styles/global';
import ComponentRoutes from './routes';
import { ThemeProvider } from 'styled-components';
import { colors } from './styles/colors';
import { fontSizes } from './styles/fontSize';
import { MachineProvider } from './context';
import CookiesBanner from './components/atoms/CookieConsent';
import { getCookieConsent } from './utils/localStorageUtils';

export function App() {
  interface ThemeInterface {
    colors: Record<string, string>;
    fontSizes: Record<string, string>;
  }

  const theme: ThemeInterface = {
    colors,
    fontSizes,
  };

  return (
    <MachineProvider>
      <ThemeProvider theme={theme}>
        <ComponentRoutes />
        {!getCookieConsent() && <CookiesBanner />}
        <GlobalStyle />
      </ThemeProvider>
    </MachineProvider>
  );
}

export default App;
