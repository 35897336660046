import { TransactionIdsProps, UserProps } from '../context/types';
import paymentService from '../services/paymentService';
import userService from '../services/userService';

const interval = 15 * 1000;

export const startCheckPaymentJob = (
  userId: string,
  transactionIds: TransactionIdsProps[],
  setTransactionIds: React.Dispatch<
    React.SetStateAction<TransactionIdsProps[]>
  >,
  updateUserInfo: <T extends keyof UserProps>(
    fieldName: T,
    newValue: UserProps[T]
  ) => void,
  setModalScreen: (screen: string) => void
) => {
  const intervalId = setInterval(async () => {
    try {
      const response = await Promise.all(
        transactionIds.map(async ({ transactionId }) => {
          return {
            transactionId,
            ...(await paymentService.getPaymentStatus(transactionId)),
          };
        })
      );

      const someTransactionWasPaid = response.some(
        (item) => item.already_confirmed
      );
      if (someTransactionWasPaid) {
        setModalScreen('payment');
        const getAmount = async () => {
          return await userService.getWallet(userId);
        };

        getAmount().then((response) => {
          updateUserInfo('amount', response.available_money);
          updateUserInfo('plushQtd', response.plush_to_recover);
          updateUserInfo('victoryAmount', response.victory_money);
        });
      }

      const transactionsIdsToKeep = response.filter(
        (item) => !item.already_confirmed && !item.cancelled
      );
      if (transactionsIdsToKeep.length !== transactionIds.length) {
        setTransactionIds(transactionsIdsToKeep);
        clearInterval(intervalId);
      }
    } catch (error) {
      console.log(error);
    }
  }, interval);
};
