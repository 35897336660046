import { useEffect } from 'react';
import { TransactionIdsProps, UserProps } from '../context/types';
import { setAuthCookie } from '../utils/cookieUtils';
import { checkAuth } from '../utils/authUtils';

export const useLogin = (
  user: UserProps,
  setWaitingList: React.Dispatch<React.SetStateAction<number>>,
  updateUserInfo: <T extends keyof UserProps>(
    fieldName: T,
    newValue: UserProps[T]
  ) => void,
  setTransactionIds: React.Dispatch<React.SetStateAction<TransactionIdsProps[]>>
) =>
  useEffect(() => {
    const checkUserAuth = async () => {
      const response = await checkAuth();
      if (response) {
        updateUserInfo('id', response.player_id);
        updateUserInfo('name', response.name);
        setAuthCookie(response.token_string);
        setWaitingList(response.quantity_of_players_waiting);
        updateUserInfo('isLogged', true);
        updateUserInfo('amount', response.wallet.available_money);
        updateUserInfo('victoryAmount', response.wallet.victory_money);
        updateUserInfo('plushQtd', response.wallet.plush_to_recover);
        updateUserInfo('plushPrice', response.game_info.plush_price);
        updateUserInfo('movePrice', response.game_info.move_price);
        if (
          response.wallet.pendent_charges &&
          response.wallet.pendent_charges.length > 0
        ) {
          setTransactionIds(
            response.wallet.pendent_charges.map((charge) => ({
              already_confirmed: false,
              transactionId: charge.charge_id,
              cancelled: false,
            }))
          );
        }
      }
    };

    if (!user.isLogged) checkUserAuth();
  }, [setTransactionIds, setWaitingList, updateUserInfo, user.isLogged]);
