import { useContext, useState } from 'react';
import Instructions from '../../atoms/Instructions';
import ButtonsContainer from '../../molecules/ButtonsContainer';
import InformationDisplay from '../../molecules/InformationDisplay';
import { MachineContext } from '../../../context';
import { useMediaQuery } from 'react-responsive';
import HelpButton from '../../atoms/HelpButton';

import * as S from './styles';
import { Alert, Snackbar } from '@mui/material';

export const InteractScreen = () => {
  const {
    plays,
    showInstructions,
    setShowInstructions,
    status,
    waitingList,
    currentPlayerName,
  } = useContext(MachineContext);
  const [open, setOpen] = useState(true);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const playStatus = status === 'P' || status === 'R';

  setTimeout(() => {
    setOpen(false);
  }, 10000);

  return (
    <S.InteractScreenContainer>
      {!isDesktop && (
        <InformationDisplay playsQuantity={plays} position={waitingList} />
      )}
      {showInstructions && !playStatus && (
        <Instructions
          onClose={() => setShowInstructions(false)}
          showSecondScreen={true}
        />
      )}
      {!playStatus && <HelpButton onClick={() => setShowInstructions(true)} />}
      {status === 'W' && !showInstructions && (
        <span>{currentPlayerName || 'Anônimo'} jogando</span>
      )}
      {playStatus && <ButtonsContainer />}
      <Snackbar
        open={open && status !== 'P'}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ top: '50%' }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          <span>
            A OnlineMachine reembolsa apenas falhas na plataforma após análise.
            Não atualize o navegador ou abandone suas jogadas.{' '}
          </span>
        </Alert>
      </Snackbar>
    </S.InteractScreenContainer>
  );
};
