import axios from 'axios';
import { ApiErrorProps } from './types';

export const api = axios.create({
  baseURL: process.env.REACT_APP_MIDLAND_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Request-id': crypto.randomUUID(),
  },
});

export const errorHandler = (error: any) => {
  if (axios.isAxiosError<ApiErrorProps, Record<string, unknown>>(error)) {
    return error.response?.data.message;
  }

  return '';
};
